<template>
  <v-container fluid>
    <v-card
      tile
      min-height="100%"
      :style="'height:' + (height - 81) + 'px; overflow: auto;'"
      class="text-left"
    >
      <v-card-title><h1 class="h1">Reports Manager</h1></v-card-title>
      <v-card-actions>
        <v-divider></v-divider>
      </v-card-actions>
      <v-card tile width="100%">
        <v-card-subtitle class="mb-2">Select Report</v-card-subtitle>
        <v-card-actions>
          <v-autocomplete
            style="width: 100%; max-width: 800px !important"
            v-model="reportID"
            :items="allReports"
            item-title="name"
            item-value="id"
            label="View / Edit Report"
            variant="outlined"
            density="compact"
            rounded="0"
            clearable
            autocomplete="off"
          >
          </v-autocomplete>
          <Create @newReportCreated="getReports" :schema="fieldSchema" />
        </v-card-actions>
        <v-card v-if="showPanel" tile elevation="2">
          <!-- report details -->
          <v-card-text>
            <v-card-subtitle
              >{{ reportObjectClone.name }} Details
            </v-card-subtitle>
            <v-card-text>
              <v-form ref="form" v-model="validation" lazy-validation>
                <v-row style="padding-top: 15px !important">
                  <v-col
                    cols="12"
                    lg="6"
                    sm="12"
                    md="6"
                    v-for="(item, key) in fieldSchema"
                    :key="key"
                    style="padding-top: 0px !important"
                  >
                    <!--     Default Report checkbox        -->
                    <v-btn
                      variant="elevated"
                      v-if="fieldSchema[key].id === 'defaultCheckbox'"
                      @click="viewDefaultReportDialog = true"
                      color="primary"
                      tile
                      aria-label="default"
                      >default options
                    </v-btn>
                    <Field
                      v-else
                      :schema="fieldSchema[key]"
                      v-model:value="reportObject[key]"
                      v-on:testDynamic="testDynamicText"
                    />
                  </v-col>
                </v-row>
              </v-form>
              <!-- I've commented out this section...as I feel we will need this functionality in the future -->
              <v-card-subtitle class="mt-0"
                >Area Comparators (for viewing report only)
              </v-card-subtitle>
              <v-card>
                <v-card-actions class="pt-0">
                  <v-select
                    v-model="selectedAreaLevel"
                    :items="areaLevels"
                    item-title="area_level_name"
                    item-value="area_level_name_abr"
                    label="Level Filter"
                    variant="outlined"
                    density="compact"
                    rounded="0"
                    clearable
                  >
                  </v-select>
                </v-card-actions>
                <v-card-actions class="pt-0">
                  <v-select
                    v-model="area"
                    :items="selectedAreaLevelsAreas"
                    item-title="area_name"
                    item-value="area_code"
                    label="Select Area"
                    variant="outlined"
                    density="compact"
                    rounded="0"
                    clearable
                    return-object
                  >
                  </v-select>
                </v-card-actions>
                <v-card-actions class="pt-0">
                  <v-select
                    v-model="selectedComparators"
                    :items="comparatorsList"
                    item-title="area_name"
                    item-value="area_code"
                    label="Comparators"
                    multiple
                    chips
                    hint="Select Comparators (in order of preference)"
                    variant="outlined"
                    density="compact"
                    rounded="0"
                    class="pt-0"
                    clearable
                    @update:modelValue="selectedComparatorsUpdated"
                    @click:clear="selectedComparatorsUpdated"
                  >
                  </v-select>
                </v-card-actions>
              </v-card>
            </v-card-text>
          </v-card-text>
          <v-card>
            <v-card-actions>
              <v-btn
                variant="elevated"
                color="warning"
                tile
                @click="
                  setLocalReportThemes();
                  themesDialog = true;
                "
                aria-label="Report Themes"
              >
                Report Themes
              </v-btn>
              &nbsp;
              <v-btn
                variant="elevated"
                :disabled="noThemesSelected || selectedComparators.length < 3"
                color="primary"
                tile
                @click="showReport()"
                aria-label="View Report"
              >
                View Report
              </v-btn>
              <v-spacer></v-spacer>
              <v-btn
                variant="elevated"
                :disabled="noThemesSelected"
                color="success"
                tile
                @click="upsertReport()"
                aria-label="Save Changes"
              >
                Save Changes
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-card>
      </v-card>
      <v-card-text
        v-if="loadingReport"
        class="text-center progress"
        tile
        elevation="0"
      >
        <v-progress-circular
          :size="180"
          :width="2"
          color="primary"
          indeterminate
          >Loading the report
        </v-progress-circular>
      </v-card-text>
    </v-card>

    <v-dialog v-model="themesDialog" width="600" scrollable persistent>
      <v-card>
        <v-card-title
          :style="
            'background-color: ' +
            this.$store.state.config.siteConfig.toolbar_colour
          "
          class="text-h6 text-center"
        >
          Report Themes
        </v-card-title>
        <v-card v-if="loadingOfThemes">
          <v-card-actions tile elevation="0" class="progress">
            <div class="progressText">
              <v-progress-circular
                :size="150"
                :width="2"
                color="primary"
                indeterminate
                >Loading Information
              </v-progress-circular>
            </div>
          </v-card-actions>
        </v-card>
        <v-card v-else>
          <v-card-actions height="70vh">
            <v-autocomplete
              style="width: 100%; max-width: 800px !important"
              v-model="selectedReportThemeID"
              :items="themesList"
              item-title="name"
              item-value="id"
              label="Add Report Theme"
              variant="outlined"
              density="compact"
              rounded="0"
              class="mt-2"
              autocomplete="off"
            >
            </v-autocomplete>
          </v-card-actions>
          <v-card-actions v-if="reportObject !== null">
            <v-spacer>
              <v-data-table
                :headers="themesTableHeaders"
                :items="filteredReportThemes"
                class="elevation-1 themeTable mb-4"
              >
                <!-- confirm deletion dialog -->
                <template v-slot:top>
                  <v-dialog v-model="dialogDelete" width="550">
                    <v-card>
                      <v-card-title
                        :style="
                          'background-color: ' +
                          $store.state.config.siteConfig.toolbar_colour
                        "
                        class="text-h6 text-center"
                      >
                        <v-spacer>Confirm Deletion?</v-spacer>
                      </v-card-title>
                      <v-card-actions>
                        <v-card-text style="text-align: center">
                          Are you sure you want to delete this theme?
                        </v-card-text>
                      </v-card-actions>
                      <v-divider></v-divider>
                      <v-card-actions>
                        <v-btn
                          variant="elevated"
                          color="primary"
                          tile
                          @click="removeTheme(false)"
                          aria-label="cancel"
                        >
                          cancel
                        </v-btn>
                        <v-spacer></v-spacer>
                        <v-btn
                          variant="elevated"
                          color="error"
                          tile
                          @click="removeTheme(true)"
                          aria-label="Delete"
                        >
                          Delete
                        </v-btn>
                      </v-card-actions>
                    </v-card>
                  </v-dialog>
                </template>

                <!--  draggable items with customised cells  -->
                <template #body>
                  <Draggable
                    v-model="filteredReportThemes"
                    item-key="id"
                    style="display: contents"
                    @end="updateOrder"
                  >
                    <template v-slot:item="{ element }">
                      <data-table-row-handler
                        :item="element"
                        :headers="themesTableHeaders"
                      >
                        <!-- draggable icon -->
                        <template v-slot:[`item.name`]="{ item }">
                          <v-icon
                            size="small"
                            :aria-posinset="
                              filteredReportThemes.indexOf(item) + 1
                            "
                            :aria-setsize="filteredReportThemes.length"
                            aria-label="draggable icon"
                            >mdi-drag
                          </v-icon>
                          <span>
                            {{ item.name }}
                          </span>
                        </template>
                        <!-- delete -->
                        <template v-slot:[`item.actions`]="{ item }">
                          <v-icon
                            aria-label="delete"
                            size="small"
                            @click="removeTheme(item, false)"
                          >
                            mdi-delete
                          </v-icon>
                        </template>
                      </data-table-row-handler>
                    </template>
                  </Draggable>
                </template>

                <!-- hide footer -->
                <template #bottom></template>
              </v-data-table>
            </v-spacer>
          </v-card-actions>
          <v-divider></v-divider>
          <v-card-actions>
            <v-btn
              variant="elevated"
              color="error"
              tile
              @click="
                themesDialog = false;
                reportObject.assigned_themes = $cloneDeep(
                  reportObjectClone.assigned_themes,
                );
              "
              aria-label="close"
            >
              close
            </v-btn>
            <v-spacer></v-spacer>
            <v-btn
              variant="elevated"
              color="success"
              tile
              @click="upsertReport"
              aria-label="save"
            >
              save
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-card>
    </v-dialog>
    <v-dialog v-model="saveChangesDialog" width="500">
      <v-card>
        <v-toolbar
          :color="this.$store.state.config.siteConfig.toolbar_colour"
          dark
          class="text-h5 text-center"
          max-height="64px"
        >
          <v-spacer>
            <v-toolbar-title>You haven't saved country</v-toolbar-title>
          </v-spacer>
        </v-toolbar>
        <v-card-text class="pa-5 text-center text-body-2 black--text">
          You switched countries but haven't saved it. Do you want to save your
          changes before viewing the report?
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-btn
            variant="elevated"
            color="error"
            tile
            @click="saveChangesDialog = false"
            aria-label="cancel"
          >
            cancel
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            variant="elevated"
            color="success"
            tile
            @click="
              saveChangesDialog = false;
              saveAndContinue = true;
              upsertReport();
            "
            aria-label="save and continue"
          >
            save and continue
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="reportIsNotPublicDialogue" width="500">
      <v-card>
        <v-toolbar
          :color="this.$store.state.config.siteConfig.toolbar_colour"
          dark
          class="text-h5 text-center"
          max-height="64px"
        >
          <v-spacer>
            <v-toolbar-title>Warning</v-toolbar-title>
          </v-spacer>
        </v-toolbar>
        <v-card-text class="pa-5 text-center text-body-2 black--text">
          You have set this report as default, however you have not set it to
          "make it visible for users". This means users will not be able to view
          this report (both the <b>summary</b> and <b>custom</b> version)
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-btn
            variant="elevated"
            color="error"
            tile
            @click="reportIsNotPublicDialogue = false"
            aria-label="close"
          >
            close
          </v-btn>
          <v-spacer />
          <v-btn
            variant="elevated"
            color="success"
            tile
            @click="
              reportIsNotPublicDialogue = false;
              reportObject.live = true;
              upsertReport();
            "
            aria-label="make visible for users"
            >make visible for users
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="testDynamicTextDialog" width="800">
      <v-card>
        <v-card-actions>
          <v-card-text style="text-align: left">
            <div v-html="testDynamicTextResponse"></div>
          </v-card-text>
        </v-card-actions>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            variant="elevated"
            color="error"
            tile
            @click="testDynamicTextDialog = false"
            aria-label="Close"
          >
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="viewDefaultReportDialog"
      scrollable
      persistent
      width="500"
      class="defaultSummary"
    >
      <v-card>
        <v-toolbar
          :color="this.$store.state.config.siteConfig.toolbar_colour"
          dark
          class="text-h6 text-center"
          max-height="64px"
        >
          <v-toolbar-title>Default Report Settings</v-toolbar-title>
        </v-toolbar>
        <v-card>
          <v-card-text>
            <!--  text with the description about two options  -->
            <v-card-actions>
              <v-alert
                type="info"
                width="100%"
                elevation="0"
                variant="outlined"
                density="compact"
                rounded="0"
              >
                <span class="ml-2"
                  >You can choose one of the options below</span
                >
              </v-alert>
            </v-card-actions>
            <v-card-actions class="mt-3">
              <ul class="ml-4">
                <li>
                  <b>Default for all.</b> Default for all clients in the current
                  country ({{
                    this.$store.state.config.customClientConfig
                      .national_comparator.country_name
                  }})
                </li>
                <li>
                  <b>Bespoke for client.</b> Only selected clients will have
                  this report as a default in the current country. No one else
                  will see it
                </li>
                <li>
                  <b>Discovery Tool.</b> This can be used in conjuction with the
                  <i>Default for all</i> checkbox OR the
                  <i>Bespoke report for client</i>. Like the other options, this
                  is specific to the current country ({{
                    this.$store.state.config.customClientConfig
                      .national_comparator.country_name
                  }})
                </li>
              </ul>
            </v-card-actions>
            <v-card-actions class="pt-5 pb-5">
              <v-checkbox
                v-model="reportObject.default"
                label="Default for all"
                :disabled="defaultCheckboxDisabled"
                title="Only within the current country"
                color="primary"
                density="compact"
                rounded="0"
              ></v-checkbox>
              <v-spacer />
              <v-checkbox
                v-model="reportObject.discovery_tool"
                label="Discovery Tool"
                :disabled="defaultCheckboxDisabled"
                title="Only within the current country"
                color="primary"
                density="compact"
                rounded="0"
              ></v-checkbox>
            </v-card-actions>
            <div style="position: absolute; width: 100%; left: 0">
              <v-divider></v-divider>
            </div>
            <v-card-actions class="pt-5">
              <v-autocomplete
                id="searchClients"
                v-model="selectedClient"
                :items="allClients"
                :loading="clientsSearchLoading"
                v-model:search="searchClient"
                loader-height="2"
                item-title="client_name"
                label="Bespoke report for client"
                placeholder="Start typing to Search"
                return-object
                :no-data-text="
                  clientsSearchLoading ? 'Searching' : 'No Results'
                "
                variant="outlined"
                density="compact"
                rounded="0"
                clearable
                autocomplete="off"
              ></v-autocomplete>
            </v-card-actions>
            <v-card-actions class="pt-5">
              <v-select
                no-data-text="No clients selected"
                :loading="removeClientLoading"
                v-model="selectedClient"
                :items="reportObject.custom_summary_reports"
                variant="outlined"
                density="compact"
                rounded="0"
                label="Clients with Access (select to remove)"
                item-title="client_name"
                return-object
              ></v-select>
            </v-card-actions>
          </v-card-text>
        </v-card>
        <v-card>
          <v-card-actions>
            <v-btn
              variant="elevated"
              color="error"
              tile
              @click="viewDefaultReportDialog = false"
              aria-label="close"
            >
              close
            </v-btn>
            <v-spacer />
            <v-btn
              variant="elevated"
              @click="upsertReport"
              color="success"
              class="white--text"
              tile
              aria-label="save"
            >
              save
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-card>
    </v-dialog>
    <!-- view report -->
    <ReportViewer ref="reportViewer" :isReportManager="reportObject.name" />
  </v-container>
  <!-- </v-app> -->
</template>

<script>
import Field from "@/components/Fields.vue";
import Create from "@/components/ReportCreate.vue";
import ReportViewer from "@/components/ReportViewer.vue";
import RMShema from "@/schemas/ReportManager";
import Draggable from "vuedraggable";
import DataTableRowHandler from "@/components/DataTableRowHandler.vue";
import { useDisplay } from "vuetify";

export default {
  name: "ReportManager",
  data: () => ({
    viewReportDialog: false,
    theseLevelsOnly: [],
    height: useDisplay().height,
    areaLevels: [],
    selectedAreaLevel: null,
    selectedAreaLevelsAreas: [],
    area: null,
    fieldSchema: RMShema,
    selectedReportThemeID: null,
    allReports: [],
    reportObject: {},
    reportObjectClone: null,
    showPanel: false,
    validation: false,
    themesDialog: false,
    deleteThemeConfirm: false,
    dialogDelete: false,
    dynamicTestText: null,
    countryOfReport: null,
    testDynamicTextDialog: false,
    testDynamicTextResponse: null,
    filteredReportThemes: [],
    themesTableHeaders: [
      {
        title: "Theme Name",
        align: "start",
        sortable: false,
        value: "name",
      },
      {
        title: "Order",
        align: "start",
        sortable: false,
        value: "order",
      },
      {
        title: "Remove",
        align: "start",
        sortable: false,
        value: "actions",
      },
    ],
    themesList: [],
    loadingOfThemes: false,
    allThemes: [],
    isCountryChanged: false,
    saveChangesDialog: false,
    saveAndContinue: false,
    reportIsNotPublicDialogue: false,
    loadingReport: false,
    viewDefaultReportDialog: false,
    allClients: [],
    clientsSearchLoading: false,
    searchClient: "",
    selectedClient: null,
    removeClientLoading: false,
    dualAccessClients: [],
    searchIteration: null,
  }),
  components: {
    Create,
    Field,
    ReportViewer,
    Draggable,
    DataTableRowHandler,
  },
  computed: {
    selectedThemes: {
      get() {
        return this.$store.state.selectedThemes;
      },
      set(value) {
        this.$store.commit("setSelectedThemes", value);
      },
    },
    reportID: {
      get() {
        return this.$store.state.reportID;
      },
      set(value) {
        this.$store.commit("setReportID", value);
      },
    },
    comparatorsList: {
      get() {
        return this.$store.state.comparatorsList;
      },
      set(value) {
        this.$store.commit("setComparatorsList", value);
      },
    },
    reportMapPrimaryAreaCode: {
      get() {
        return this.$store.state.reportMapPrimaryAreaCode;
      },
      set(value) {
        this.$store.commit("setReportMapPrimaryAreaCode", value);
      },
    },
    selectedComparators: {
      get() {
        return this.$store.state.selectedComparators;
      },
      set(value) {
        this.$store.commit("setSelectedComparators", value);
      },
    },
    whichCountryThemes: {
      get() {
        return this.$store.state.whichCountryThemes;
      },
      set(value) {
        this.$store.commit("setWhichCountryThemes", value);
      },
    },
    selectedCountry() {
      return this.reportObject.which_country_themes;
    },
    noThemesSelected() {
      return this.filteredReportThemes.length === 0;
    },
    defaultCheckboxDisabled() {
      return this.reportObject.default && this.reportObjectClone.default;
    },
  },
  props: {
    model: null,
    item: {},
    disable: {
      type: Boolean,
      required: false,
      default: false,
    },
    relatedModelResults: {},
  },
  mounted() {
    if (this.$store.state.config.siteConfig.site_country === "aus") {
      this.fieldSchema.which_country_themes.radioItems = ["Australia"];
    }
    // clear v-select field
    this.reportID = null;
    this.getReports();
    this.getAreaLevels();
    this.selectedThemes = [];
    // we use these so we can view the report in all it's glory
    this.setDefaultComparators();
    this.updateImportLevelsList();
  },
  methods: {
    updateImportLevelsList() {
      this.theseLevelsOnly = [];
      for (
        var i = 0;
        i < this.$store.state.config.siteConfig.area_level_names.length;
        i++
      ) {
        if (
          this.$store.state.config.siteConfig.area_level_names[i]
            .area_level_name_abr !== "postcode"
        ) {
          this.theseLevelsOnly.push(
            this.$store.state.config.siteConfig.area_level_names[i]
              .area_level_name_abr,
          );
        }
      }
    },
    setDefaultComparators() {
      switch (
        this.$store.getters.customClientConfig.national_comparator
          .area_code_prefix
      ) {
        case "E":
          this.selectedComparators = ["E06000043", "E92000001", "E12000008"];
          this.comparatorsList = [
            {
              id: 6912,
              area_code: "E06000043",
              area_name: "Brighton and Hove",
            },
            {
              id: 6958,
              area_code: "E92000001",
              area_name: "England",
            },
            {
              id: 6811,
              area_code: "E12000008",
              area_name: "South East",
            },
          ];
          break;
        case "W":
          this.selectedComparators = ["W06000015", "W92000004", "W06000009"];
          this.comparatorsList = [
            {
              id: 6912,
              area_code: "W06000015",
              area_name: "Cardiff",
            },
            {
              id: 6958,
              area_code: "W92000004",
              area_name: "Wales",
            },
            {
              id: 6811,
              area_code: "W06000009",
              area_name: "Pembrokeshire",
            },
          ];
          break;
        case "S":
          this.selectedComparators = ["S12000036", "S92000003", "S12000034"];
          this.comparatorsList = [
            {
              id: 6912,
              area_code: "S12000036",
              area_name: "City of Edinburgh",
            },
            {
              id: 6958,
              area_code: "S92000003",
              area_name: "Scotland",
            },
            {
              id: 6811,
              area_code: "S12000034",
              area_name: "Aberdeenshire",
            },
          ];
          break;
        case "A":
          this.selectedComparators = ["ALGA31000", "ANATAUS", "ASTE4"];
          this.comparatorsList = [
            {
              id: 453635,
              area_code: "ALGA31000",
              area_name: "Brisbane",
            },
            {
              id: 2,
              area_code: "ANATAUS",
              area_name: "Australia",
            },
            {
              id: 453727,
              area_code: "ASTE4",
              area_name: "South Australia",
            },
          ];
          break;
        default:
          console.error("unknown country");
      }
    },
    getAreaLevels() {
      this.$axios
        .get("/list-area-levels")
        .then(
          function (response) {
            // only display the required levels
            for (var i = 0; i < response.data.length; i++) {
              if (
                this.theseLevelsOnly.includes(
                  response.data[i].area_level_name_abr,
                )
              ) {
                this.areaLevels.push(response.data[i]);
              }
            }
            this.correctAreaLevelsOrder(this.areaLevels, this.theseLevelsOnly);
          }.bind(this),
        )
        .catch(
          function (error) {
            // handle error
            console.error(error);
            this.emit.emit("systemMessage", {
              message: error.response.data.message,
              title: "Error! Failed to get Area Levels",
              timeout: -1,
              colour: "red",
            });
          }.bind(this),
        );
    },
    correctAreaLevelsOrder(inputArray, orderArray) {
      // Create a map to store the index of each area_level_name_abr
      const orderMap = {};
      orderArray.forEach((level, index) => {
        orderMap[level] = index;
      });

      // Sort the inputArray based on the index of area_level_name_abr in orderMap
      inputArray.sort((a, b) => {
        const orderA =
          orderMap[a.area_level_name_abr] !== undefined
            ? orderMap[a.area_level_name_abr]
            : Number.MAX_SAFE_INTEGER;
        const orderB =
          orderMap[b.area_level_name_abr] !== undefined
            ? orderMap[b.area_level_name_abr]
            : Number.MAX_SAFE_INTEGER;
        return orderA - orderB;
      });

      inputArray.forEach((item, index) => {
        item.tabIndex = index + 1;
      });

      this.areaLevels = inputArray;
    },
    getAreaLevelsAreas() {
      this.$axios
        .get("/list-areas-by-level/" + this.selectedAreaLevel)
        .then(
          function (response) {
            // handle success
            this.selectedAreaLevelsAreas = response.data;
            this.emit.emit("systemBusy", false);
          }.bind(this),
        )
        .catch(
          function (error) {
            this.emit.emit("systemBusy", false);
            // handle error
            console.error(error);
            this.emit.emit("systemMessage", {
              message: error.response.data.message,
              title: "Error! Failed to get Area Levels",
              timeout: -1,
              colour: "red",
            });
          }.bind(this),
        );
    },
    showReport() {
      if (
        this.whichCountryThemes !==
        this.reportObjectClone.which_country_themes.substring(0, 1)
      ) {
        this.isCountryChanged = true;
        this.saveChangesDialog = true;
        this.themesDialog = false;
      } else {
        this.isCountryChanged = false;
        this.$refs.reportViewer.initiateReportGeneration();
      }
    },
    removeTheme(item) {
      if (typeof item === "object") {
        this.dialogDelete = true;
        this.reportObject.assigned_themes.forEach(function (theme) {
          if (theme.id === item.id) {
            theme.order = "remove";
          }
        });
      } else if (item === true) {
        this.upsertReport();
      } else {
        this.reportObject = this.$cloneDeep(this.reportObjectClone);
        this.dialogDelete = false;
      }
    },
    getAllReportThemes() {
      this.loadingReport = true;
      this.$axios
        .get("/reports-themes-all-countries")
        .then(
          function (response) {
            // handle success
            this.allThemes = response.data;

            // call getSelectedReport() here just once to get the report AFTER allThemes has been populated
            this.getSelectedReport();
          }.bind(this),
        )
        .catch(
          function (error) {
            // handle error
            console.error(error);
            this.emit.emit("systemMessage", {
              message: error.response.data.message,
              title: "Error! Failed to get all reports",
              timeout: -1,
              colour: "red",
            });
          }.bind(this),
        );
    },
    getReports() {
      this.$axios
        .get("/reports")
        .then(
          function (response) {
            // handle success
            this.allReports = response.data;
          }.bind(this),
        )
        .catch(
          function (error) {
            // handle error
            console.error(error);
            this.emit.emit("systemMessage", {
              message: error.response.data.message,
              title: "Error! Failed to get all reports",
              timeout: -1,
              colour: "red",
            });
          }.bind(this),
        );
    },
    getSelectedReport() {
      this.loadingReport = true;
      this.showPanel = false;
      this.$axios
        .get("/reports/" + this.reportID)
        .then(
          function (response) {
            // handle success
            this.reportObject = response.data;
            this.whichCountryThemes =
              this.reportObject.which_country_themes.substr(0, 1);
            this.setLocalReportThemes();
            this.reportObjectClone = this.$cloneDeep(this.reportObject);
            this.loadingReport = false;
            this.showPanel = true;
          }.bind(this),
        )
        .catch(
          function (error) {
            this.emit.emit("systemBusy", false);
            // handle error
            console.error(error);
            this.emit.emit("systemMessage", {
              message: error.response.data.message,
              title: "Error! Failed to get selected report",
              timeout: -1,
              colour: "red",
            });
          }.bind(this),
        );
    },
    upsertReport() {
      // if a user selected this report by default and hasn't selected it to the public, then the warning pops up
      if (this.reportObject.default && !this.reportObject.live) {
        this.reportIsNotPublicDialogue = true;
        return;
      }

      this.emit.emit("systemBusy", true);
      this.resetApiValidationErrors();

      this.$refs.form.validate().then(({ valid: isFormValid }) => {
        if (isFormValid) {
          // make the call!
          this.$axios
            .put("/reports/" + this.reportID, this.reportObject)
            .then((response) => {
              this.emit.emit("systemMessage", {
                title: "Update Complete",
                message: "Success!",
                timeout: 3000,
                colour: "green",
              });
              // update the selected report
              this.reportObject = response.data;
              this.reportObjectClone = this.$cloneDeep(this.reportObject);

              this.dialogDelete = false;
              this.selectedReportThemeID = null;

              // if user selected save and continue viewing report then do that
              if (this.saveAndContinue) {
                this.$refs.reportViewer.initiateReportGeneration();

                this.saveAndContinue = false;
              }

              this.setLocalReportThemes();
              this.updateOrder();
              this.emit.emit("systemBusy", false);
            })
            .catch((error) => {
              this.ccc = error.response.data;
              this.emit.emit("systemBusy", false);
              this.emit.emit("systemMessage", {
                message: error.response.data.message,
                title: "Error! Report Update Failed",
                timeout: -1,
                colour: "red",
              });
              this.apiValidationErrors(error.response.data.errors);
            });
        }
      });
    },
    apiValidationErrors(errors) {
      for (const field in errors) {
        this.fieldSchema[field].apiResponseError = errors[field];
      }
    },
    resetApiValidationErrors() {
      for (var field in this.fieldSchema) {
        this.fieldSchema[field].apiResponseError = null;
      }
    },
    testDynamicText(text) {
      this.emit.emit("systemBusy", true);
      this.testDynamicTextResponse = null;
      this.selectedComparators;
      let test = { text: text };
      for (var i = 0; i < this.selectedComparators.length; i++) {
        test[i] = this.selectedComparators[i];
      }
      // make the call!
      this.$axios
        .post("/reports-themes-test-dynamic-text", test)
        .then((response) => {
          this.testDynamicTextDialog = true;
          this.testDynamicTextResponse = response.data;
          this.emit.emit("systemBusy", false);
        })
        .catch((error) => {
          this.emit.emit("systemBusy", false);
          this.emit.emit("systemMessage", error.response.data.message);
        });
    },
    setLocalReportThemes() {
      // first get the themes for the v-select in the dialog
      var selectedCountry = this.whichCountryThemes;
      this.themesList = this.allThemes.filter(
        (theme) => theme.country === selectedCountry,
      );
      // now get the themes for the data-table
      this.filteredReportThemes = this.reportObject.assigned_themes
        .filter((theme) => theme.country === selectedCountry)
        .map((themeObj) => {
          var themeName = this.allThemes.find(
            (theme) => theme.id === themeObj.themeID,
          ).name;
          return {
            id: themeObj.id,
            name: themeName,
            order: themeObj.order,
            country: themeObj.country,
          };
        });
    },
    updateOrder() {
      // Update the order property of the items in the list
      this.filteredReportThemes.forEach(
        (item, index) => (item.order = index + 1),
      );

      // Update the order property of the corresponding item and rest of the elements in the same country
      this.reportObject.assigned_themes.forEach((item) => {
        if (item.country === this.whichCountryThemes) {
          item.order = this.filteredReportThemes.find(
            (theme) => theme.id === item.id,
          ).order;
        }
      });
    },
    fetchClients(name) {
      this.clientsSearchLoading = true;
      this.$axios
        .get("/clients/search/" + name)
        .then((response) => {
          this.allClients = response.data;
        })
        .catch((error) => {
          this.emit.emit("systemMessage", {
            message: error.response.data.message,
            title: "Error! Failed to get client",
            timeout: -1,
            colour: "red",
          });
        })
        .finally(() => (this.clientsSearchLoading = false));
    },
    selectedComparatorsUpdated(eventComparators) {
      //if all comparators have been cleared set empty array and return
      if (eventComparators.length === 0) {
        this.comparatorsList = [];
        return;
      }
      //update comparatorsList to match selected comparators and prevent extra columns in charts
      let comparatorsListClone = [...this.comparatorsList];

      comparatorsListClone.forEach((c) => {
        if (!eventComparators.includes(c.area_code)) {
          const itemIndex = comparatorsListClone.findIndex((cl) => {
            return cl.area_code == c.area_code;
          });
          comparatorsListClone.splice(itemIndex, 1);
        }
      });

      this.comparatorsList = comparatorsListClone;
    },
  },
  watch: {
    selectedAreaLevel(val) {
      this.selectedAreaLevelsAreas = [];
      if (val) {
        this.getAreaLevelsAreas();
      }
    },
    area(val) {
      if (val) {
        if (!this.selectedComparators.includes(val.area_code)) {
          this.selectedComparators.push(val.area_code);
        }
        if (!this.comparatorsList.includes(val)) {
          this.comparatorsList.push(val);
        }
      }
    },
    selectedReportThemeID: {
      handler() {
        if (this.selectedReportThemeID) {
          // add new theme to the report
          this.reportObject.assigned_themes.push({
            themeID: this.selectedReportThemeID,
            order: null,
            country: this.whichCountryThemes,
          });

          // save it
          this.upsertReport();
        }
      },
    },
    reportID: {
      handler() {
        if (this.reportID) {
          // if we've already got the themes then don't get them again
          if (!this.allThemes.length) {
            this.getAllReportThemes();
          } else {
            this.getSelectedReport();
          }
        } else {
          this.showPanel = false;
        }
      },
      deep: true,
    },
    selectedCountry: {
      handler() {
        // if report is selec ted then get the themes for the selected country
        if (this.reportID) {
          this.whichCountryThemes = this.selectedCountry.substring(0, 1);
          this.countryOfReport = this.reportObject.country.substring(0, 1);

          this.setLocalReportThemes();
        }
      },
      deep: true,
    },
    searchClient(val) {
      // prevent the search from firing too often
      clearTimeout(this.searchIteration);

      this.searchIteration = setTimeout(() => {
        // check if the value is not already in the list
        if (
          val &&
          this.allClients &&
          !this.allClients.some((item) => item.client_name === val)
        ) {
          this.fetchClients(val);
        }
      }, 500);
    },
    selectedClient: {
      handler(val) {
        if (val) {
          this.clientsSearchLoading = true;
          this.$axios
            .post("/set-custom-summary-report", {
              report_id: this.reportObject.id,
              client_id: Object.prototype.hasOwnProperty.call(
                this.selectedClient,
                "client_id",
              )
                ? this.selectedClient.client_id
                : this.selectedClient.id,
            })
            .then((response) => {
              // handle success
              this.reportObject.custom_summary_reports = response.data;
            })
            .catch((error) => {
              let alreadyDefaultForAll = error.response.status === 422;

              this.emit.emit("systemMessage", {
                message: error.response.data.message,
                title: alreadyDefaultForAll
                  ? "Hang on"
                  : "Error! Failed to add client to them",
                timeout: -1,
                colour: alreadyDefaultForAll ? "blue" : "red",
              });
            })
            .finally(() => {
              // reset the search
              this.clientsSearchLoading = false;
              this.selectedClient = null;
              this.searchClient = null;
            });
        }
      },
    },
  },
};
</script>

<style scoped></style>
<style>
.defaultSummary .v-input__details {
  display: none !important;
}

.themeTable i {
  margin: 13px;
}

ul {
  padding-left: 24px;
}
</style>
