<template>
  <span>
    <v-dialog v-model="viewInfoDialog" width="550" scrollable>
      <v-card>
        <v-toolbar
          :style="
            'background-color: ' +
            this.$store.state.config.siteConfig.toolbar_colour
          "
        >
          <v-toolbar-title class="text-left"
            >More Info</v-toolbar-title
          ></v-toolbar
        >
        <v-card-text v-if="loadingMoreInfo" style="height: 500px">
          <div class="text-center" style="margin-top: 35%">
            <v-progress-circular
              :size="120"
              :width="3"
              color="#51627C"
              indeterminate
              >Busy!</v-progress-circular
            >
          </div>
        </v-card-text>
        <v-card-text v-else-if="editMode" style="height: 500px">
          <v-card-actions>
            <v-select
              :items="multipleMetadataSelectList"
              item-value="id"
              item-title="text"
              variant="outlined"
              density="compact"
              rounded="0"
              label="Select"
              multiple
              hint="select to update multiple indicators"
              persistent-hint
              class="fields"
            >
              <template v-slot:item="{ item }">
                <v-list-item>
                  <v-checkbox
                    v-model="updateMetadataArray"
                    :label="item.title"
                    :value="item.value"
                    @update:modelValue="selectAll(item.value)"
                  ></v-checkbox>
                </v-list-item>
              </template>
            </v-select>
          </v-card-actions>
          <div>
            <strong>Indicator Name: </strong
            >{{ this.selectedItem.indicator_name }}
          </div>
          <div>
            <strong>Indicator Code: </strong
            >{{ this.selectedItem.indicator_code }}
          </div>
          <div>
            <strong>Indicator Date: </strong
            >{{ formatDate(this.selectedItem.date) }}
          </div>
          <div>
            <strong>Muliple Indicator Select Count: </strong
            ><span style="color: red">{{ this.multipleUpdateCount }}</span>
          </div>
          <v-divider class="mt-5"></v-divider>
          <v-card-actions class="mt-5">
            <Field
              :schema="formFieldSchema[editFieldName]"
              v-model:value="selectedItem[editFieldName]"
            />
          </v-card-actions>
        </v-card-text>
        <v-card-text v-else style="height: 500px">
          <v-card-text v-for="(item, index) in this.selectedItem" :key="index">
            <strong>{{ index }}</strong>
            <v-icon
              v-if="!formFieldSchema[index].readOnly"
              class="editIcon"
              title="click to edit information"
              label="edit"
              @click="edit(index)"
              >mdi-pencil</v-icon
            ><br />{{ item }}
          </v-card-text>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions class="text-body-1 text-left">
          <v-btn
            tile
            color="error"
            variant="elevated"
            @click="confirmDeleteDialog = true"
            aria-label="Delete Data"
          >
            Delete Data
          </v-btn>
          <span class="ml-5">
            Remove data for this indicator. This action <i>cannot</i> be undone.
          </span>
        </v-card-actions>
        <v-divider></v-divider>
        <v-card-actions>
          <v-btn
            tile
            v-if="editMode"
            color="success"
            variant="elevated"
            @click="confirmDialog = true"
            aria-label="save"
          >
            save
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            tile
            v-if="editMode"
            color="primary"
            variant="elevated"
            @click="editMode = false"
            aria-label="back"
          >
            back
          </v-btn>
          <v-btn
            tile
            v-else
            color="primary"
            variant="elevated"
            @click="viewInfoDialog = false"
            aria-label="close"
          >
            close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="confirmDeleteDialog" width="700" :scrim="false">
      <v-card v-if="!deleteResults">
        <v-toolbar
          :style="
            'background-color: ' +
            this.$store.state.config.siteConfig.toolbar_colour
          "
        >
          <v-toolbar-title v-if="selectedItem" class="text-left">
            Delete Data For:
            <i
              >{{ this.selectedItem.indicator_code.replace(/-/g, "") }}.{{
                this.selectedItem.date.replace(/-/g, "")
              }}</i
            >?</v-toolbar-title
          ></v-toolbar
        >
        <v-card-actions>
          <v-card-text v-if="selectedItem" class="text-left">
            <v-alert type="error" outlined icon="mdi-alert" class="mb-4">
              Are you sure you want to delete data for
              {{ this.selectedItem.indicator_code.replace(/-/g, "") }}.{{
                this.selectedItem.date.replace(/-/g, "")
              }}?
            </v-alert>
            <div class="mt-4">
              <strong>ID:</strong> {{ this.selectedItem.id }}
            </div>
            <div class="mt-4">
              <strong>Indicator Code:</strong>
              {{ this.selectedItem.indicator_code }}
            </div>
            <div class="mt-4 mb-8">
              <strong>Date:</strong> {{ this.selectedItem.date }}
            </div>

            Select which countries to remove: <br />
            <v-row
              v-if="this.$store.state.config.siteConfig.site_country !== 'aus'"
              justify="center"
            >
              <v-col cols="3">
                <v-checkbox
                  :disabled="!selectedItem.eng"
                  v-model="engCheckboxValue"
                  label="England"
                ></v-checkbox>
              </v-col>
              <v-col cols="3">
                <v-checkbox
                  :disabled="!selectedItem.sct"
                  v-model="sctCheckboxValue"
                  label="Scotland"
                ></v-checkbox>
              </v-col>
              <v-col cols="3">
                <v-checkbox
                  :disabled="!selectedItem.wls"
                  v-model="wlsCheckboxValue"
                  label="Wales"
                ></v-checkbox>
              </v-col>
            </v-row>
            <v-row v-else justify="center">
              <v-col>
                <v-checkbox
                  :disabled="!selectedItem.aus"
                  v-model="ausCheckboxValue"
                  label="Australia"
                ></v-checkbox>
              </v-col>
            </v-row>
            You <i>cannot</i> undo this action!
          </v-card-text>
        </v-card-actions>
        <v-divider></v-divider>
        <v-card-actions>
          <v-btn
            tile
            color="primary"
            variant="elevated"
            style="width: 150px"
            @click="confirmDeleteDialog = false"
            aria-label="cancel"
          >
            cancel
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            tile
            v-if="!deleteFlag"
            color="error"
            variant="elevated"
            :disabled="!minCountryCheck"
            style="width: 150px"
            @click="deleteDataForIndicator()"
            aria-label="Delete"
          >
            Delete
          </v-btn>
          <v-btn
            tile
            v-else
            variant="elevated"
            disabled
            style="width: 150px"
            aria-label="Deleting"
          >
            <v-progress-circular
              color="#51627C"
              indeterminate
            ></v-progress-circular>
          </v-btn>
        </v-card-actions>
      </v-card>
      <v-card v-else>
        <v-toolbar
          :style="
            'background-color: ' +
            this.$store.state.config.siteConfig.toolbar_colour
          "
        >
          <v-toolbar-title class="text-left"
            >Delete Results</v-toolbar-title
          ></v-toolbar
        >
        <v-card-actions>
          <v-card-text style="text-align: center">
            <v-alert
              v-if="deleteResults.status == 'success'"
              type="success"
              variant="outlined"
              icon="mdi-alert"
              class="mb-4"
            >
              {{ deleteResults.message }}
            </v-alert>
            <v-alert v-else type="error" outlined icon="mdi-alert" class="mb-4">
              {{ deleteResults.message }}
            </v-alert>
          </v-card-text>
        </v-card-actions>
        <v-divider></v-divider>
        <v-card-actions>
          <v-btn
            tile
            color="primary"
            variant="elevated"
            @click="closeDeleteDialog()"
            aria-label="Close"
          >
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="confirmDialog" width="700" :scrim="false">
      <v-card>
        <v-card-title
          v-if="selectedItem"
          :style="
            'background-color: ' +
            this.$store.state.config.siteConfig.toolbar_colour
          "
          class="text-h6"
        >
          Confirm Update for "{{ this.selectedItem.indicator_code }}"?
        </v-card-title>
        <v-card-actions>
          <v-card-text v-if="selectedItem" style="text-align: center">
            Are you sure you want to update
            <span style="color: red">{{ this.multipleUpdateCount + 1 }}</span>
            indicator(s)? <br /><br />
            <strong>Field Name:</strong> "{{ this.editFieldName }}" <br /><br />
            <strong>New Value:</strong>
            {{ this.selectedItem[this.editFieldName] }} <br /><br />
            You <i>cannot</i> undo this action!
          </v-card-text>
        </v-card-actions>
        <v-divider></v-divider>
        <v-card-actions>
          <v-btn
            color="primary"
            variant="outlined"
            tile
            @click="confirmDialog = false"
            aria-label="cancel"
          >
            cancel
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            color="success"
            variant="outlined"
            tile
            @click="saveUpdate()"
            aria-label="Save"
          >
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="computedMetaDataDialog"
      fullscreen
      hide-overlay
      persistent
      scrollable
    >
      <v-card>
        <v-toolbar
          :style="
            'background-color: ' + $store.state.config.siteConfig.toolbar_colour
          "
        >
          <v-btn
            icon
            dark
            @click="computedMetaDataDialog = false"
            aria-label="Close"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>Indicators</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-text-field
              bg-color="#fff"
              type="text"
              v-model="code"
              label="Search Indicator Code"
              variant="outlined"
              density="compact"
              rounded="0"
              class="fields mt-3 mr-1"
              clearable
              style="width: 300px"
              @keyup="
                search = null;
                firstLetter = null;
              "
              @keyup.enter="searchForIndicator"
              autocomplete="off"
            ></v-text-field>
            <v-text-field
              bg-color="#fff"
              type="text"
              v-model="search"
              label="Search Indicator Name"
              variant="outlined"
              density="compact"
              rounded="0"
              class="fields mt-3 mr-1"
              clearable
              background-color="#ffffff"
              style="width: 300px"
              @keyup="
                code = null;
                firstLetter = null;
              "
              @keyup.enter="searchForIndicator"
              autocomplete="off"
            ></v-text-field>
            <v-select
              bg-color="#fff"
              v-model="firstLetter"
              :items="letters"
              label="First Letter"
              variant="outlined"
              density="compact"
              rounded="0"
              class="fields mt-3 mr-1"
              clearable
              background-color="#ffffff"
              style="width: 140px"
              @change="
                code = null;
                search = null;
              "
            ></v-select>
            <v-select
              bg-color="#fff"
              v-model="live"
              :items="[
                { title: 'Live', value: true },
                { title: 'Non-Live', value: false },
              ]"
              label="Live?"
              variant="outlined"
              density="compact"
              rounded="0"
              class="fields mt-3 mr-1"
              clearable
              background-color="#ffffff"
              style="width: 100px"
            ></v-select>
            <v-select
              bg-color="#fff"
              v-model="dynamics"
              :items="[
                { title: 'Dynamic', value: true },
                { title: 'Non-Dynamic', value: false },
              ]"
              label="Dynamic?"
              variant="outlined"
              density="compact"
              rounded="0"
              class="fields mt-3 mr-1"
              clearable
              background-color="#ffffff"
              style="width: 190px"
            ></v-select>
            <v-select
              bg-color="#fff"
              v-model="order"
              :items="[
                { title: 'Date Desc', value: 'dd' },
                { title: 'Date Asc', value: 'da' },
                { title: 'Name Desc', value: 'cd' },
                { title: 'Name Asc', value: 'ca' },
              ]"
              label="Order By"
              variant="outlined"
              density="compact"
              rounded="0"
              class="fields mt-3 mr-1"
              background-color="#ffffff"
              style="width: 150px"
            ></v-select>
          </v-toolbar-items>
          <v-btn
            tile
            :loading="searching"
            :disabled="searching"
            color="success"
            variant="elevated"
            @click="searchForIndicator"
            aria-label="Search"
          >
            Search
            <template v-slot:loader>
              <span class="custom-loader">
                <v-icon light>mdi-cached</v-icon>
              </span>
            </template>
          </v-btn>
        </v-toolbar>
        <v-card-text style="height: 95vh" @scroll="lazyLoad">
          <!-- loading UX -->
          <v-card
            v-if="searchResults.length === 0"
            tile
            elevation="0"
            class="progress"
          >
            <v-card-actions v-if="searching">
              <div class="progressText">
                <v-progress-circular
                  :size="200"
                  :width="3"
                  color="#51627C"
                  indeterminate
                  >Searching</v-progress-circular
                >
              </div>
            </v-card-actions>
            <v-card-actions v-else>
              <div class="progressText">No Search Results</div>
            </v-card-actions>
          </v-card>
          <!-- results -->
          <v-hover
            v-slot="{ hover }"
            v-else
            v-for="(item, index) in searchResults"
            :key="index"
          >
            <v-card
              :elevation="hover ? 4 : 1"
              :class="{ 'on-hover': hover }"
              class="mx-auto mt-2"
            >
              <v-card-actions>
                <v-card-text>
                  <v-row align="center" class="mx-0">
                    <div style="width: 30%; margin-right: 10px">
                      <strong>Name: </strong><br /><span
                        class="moreInfo"
                        title="Click for More Information"
                        @click="getInfo(item)"
                        >{{ item.indicator_name }}</span
                      >
                    </div>
                    <div style="width: 15%">
                      <strong>Code: </strong><br />{{ item.indicator_code }}
                    </div>
                    <div style="width: 10%">
                      <strong>Date: </strong><br />{{ item.date }}
                    </div>
                    <div style="width: 10%">
                      <strong>Live: </strong><br />{{ item.live }}
                    </div>
                    <div style="width: 15%">
                      <strong>Numerator: </strong><br />{{ item.numerator_id }}
                    </div>
                    <div style="width: 15%">
                      <strong>Denominator: </strong><br />{{
                        item.denominator_id
                      }}
                    </div>
                  </v-row>
                </v-card-text>
              </v-card-actions>
            </v-card>
          </v-hover>
        </v-card-text>
      </v-card>
    </v-dialog>
  </span>
</template>

<script>
import Field from "@/components/Fields.vue";
import DataManagerFormSchema from "@/schemas/DataManager";
// eslint-disable-next-line no-unused-vars
import { formatDate } from "@/mixins/FormatDate";

export default {
  name: "EDITMETADATA",
  data: () => ({
    formatDate: formatDate,
    order: "dd",
    engCheckboxValue: false,
    sctCheckboxValue: false,
    wlsCheckboxValue: false,
    ausCheckboxValue: false,
    deleteResults: null,
    deleteFlag: false,
    confirmDeleteDialog: false,
    confirmDialog: false,
    editFieldName: null,
    formFieldSchema: DataManagerFormSchema,
    updateMetadataArray: [],
    multipleMetadataSelectList: [],
    editMode: false,
    code: null,
    live: null,
    skip: 0,
    take: 100,
    search: null,
    firstLetter: null,
    dynamics: null,
    letters: [
      "A",
      "B",
      "C",
      "D",
      "E",
      "F",
      "G",
      "H",
      "I",
      "J",
      "K",
      "L",
      "M",
      "N",
      "O",
      "P",
      "Q",
      "R",
      "S",
      "T",
      "Y",
      "V",
      "W",
      "X",
      "Y",
      "Z",
    ],
    searching: false,
    searchResults: [],
    viewInfoDialog: false,
    loadingMoreInfo: true,
    selectedItem: null,
  }),
  components: {
    Field,
  },
  props: {
    metaDataDialog: {
      type: Boolean,
      required: true,
      default: false,
    },
  },
  computed: {
    computedMetaDataDialog: {
      get() {
        return this.metaDataDialog;
      },
      set(val) {
        this.$emit("update:metaDataDialog", val);
      },
    },
    multipleUpdateCount: {
      get() {
        let counter = 0;
        for (var i = 0; i < this.updateMetadataArray.length; i++) {
          if (
            this.updateMetadataArray[i] !== 0 &&
            this.updateMetadataArray[i] !== this.selectedItem.id
          ) {
            counter++;
          }
        }
        return counter;
      },
    },
    minCountryCheck: {
      get() {
        return (
          this.engCheckboxValue ||
          this.sctCheckboxValue ||
          this.wlsCheckboxValue ||
          this.ausCheckboxValue
        );
      },
    },
  },
  mounted() {},
  unmounted() {
    this.updateImportLevelsList();
  },
  methods: {
    updateImportLevelsList() {
      this.formFieldSchema.import_level.radioItems = [];
      for (
        var i = 0;
        i < this.$store.state.config.siteConfig.area_level_names.length;
        i++
      ) {
        if (
          this.$store.state.config.siteConfig.area_level_names[i]
            .area_level_name_abr !== "postcode"
        ) {
          this.formFieldSchema.import_level.radioItems.push(
            this.$store.state.config.siteConfig.area_level_names[i]
              .area_level_name_abr,
          );
        }
      }
    },
    deleteDataForIndicator() {
      // build the array of needed data to send to the delete endpoint
      let deleteArray = [];

      // Add selected item's id, indicator_code, date, and country checkbox values to deleteArray
      deleteArray.push({
        id: this.selectedItem.id,
        indicator_code: this.selectedItem.indicator_code,
        date: this.selectedItem.date,
        eng: this.engCheckboxValue,
        sct: this.sctCheckboxValue,
        wls: this.wlsCheckboxValue,
        aus: this.ausCheckboxValue,
      });

      // say we're working on it
      this.deleteFlag = true;

      // send delete as post to use the array
      this.$axios
        .post("/remove-indicator-data", deleteArray)
        .then((response) => {
          this.deleteResults = response.data;
          this.deleteFlag = false;
        })
        .catch((error) => {
          console.error(error.response.data);
          this.deleteFlag = false;
          this.emit.emit("systemBusy", false);
          this.emit.emit(
            "systemMessage",
            error.response.data,
            "Error! Failed to delete indicator data",
            0,
            "red",
          );
        });
    },
    closeDeleteDialog() {
      this.confirmDeleteDialog = false;
      this.deleteResults = null;
      this.engCheckboxValue = false;
      this.sctCheckboxValue = false;
      this.wlsCheckboxValue = false;
      this.ausCheckboxValue = false;
    },
    selectAll(id = null) {
      if (id === 0 && this.updateMetadataArray.includes(0)) {
        this.updateMetadataArray = this.multipleMetadataSelectList.map(
          ({ id }) => id,
        );
      } else if (id === 0 && !this.updateMetadataArray.includes(0)) {
        this.updateMetadataArray = [];
      }
    },
    saveUpdate() {
      this.loadingMoreInfo = true;
      this.confirmDialog = false;

      // if we're updating import level, 'OA' is just user facing and should be stored as a null
      if (
        this.editFieldName === "import_level" &&
        this.selectedItem[this.editFieldName] === "OA"
      ) {
        this.selectedItem[this.editFieldName] = null;
      }

      this.updateMetadataArray.push(this.selectedItem.id);
      this.$axios
        .put("/standard-metadata-field", {
          indicaterCodes: this.updateMetadataArray,
          field: this.editFieldName,
          value: this.selectedItem[this.editFieldName],
        })
        .then((response) => {
          console.log(response.data);
          this.searchForIndicator();
          this.viewInfoDialog = false;
        })
        .catch((error) => {
          console.error(error.response.data);
          this.emit.emit("systemBusy", false);
          this.emit.emit(
            "systemMessage",
            error.response.data,
            "Error!",
            0,
            "red",
          );
        });
    },
    edit(fieldName) {
      this.editFieldName = null;
      this.multipleMetadataSelectList = [];
      this.updateMetadataArray = [];
      if (!this.formFieldSchema[fieldName].readOnly) {
        this.loadingMoreInfo = true;
        this.editMode = true;
        this.editFieldName = fieldName;
        this.$axios
          .get("/standard-metadata/by-code/" + this.selectedItem.indicator_code)
          .then(
            function (response) {
              // handle success
              this.multipleMetadataSelectList = response.data;
              this.multipleMetadataSelectList.unshift({
                text: "Select All",
                id: 0,
              });
              this.loadingMoreInfo = false;
            }.bind(this),
          )
          .catch(
            function (error) {
              // handle error
              console.error(error);
              this.emit.emit("systemMessage", {
                message: error.response.data.message,
                title: "Error! Failed to load indicators",
                timeout: -1,
                colour: "red",
              });
            }.bind(this),
          );
      }
    },
    getInfo(item) {
      this.selectedItem = null;
      this.loadingMoreInfo = true;
      this.viewInfoDialog = true;
      this.$axios
        .get("/standard-metadata/" + item.id)
        .then(
          function (response) {
            // handle success
            this.selectedItem = response.data;
            this.loadingMoreInfo = false;
          }.bind(this),
        )
        .catch(
          function (error) {
            // handle error
            console.error(error);
            this.emit.emit("systemMessage", {
              message: error.response.data.message,
              title: "Error! Failed to search indicators",
              timeout: -1,
              colour: "red",
            });
          }.bind(this),
        );
    },
    searchForIndicator() {
      this.skip = 0;
      this.searchResults = [];
      this.doSearch();
    },
    doSearch() {
      if (!this.searching) {
        this.searching = true;
        this.$axios
          .get(
            "/paginated-metadata-lite?skip=" +
              this.skip +
              "&take=200&live=" +
              this.live +
              "&dynamics=" +
              this.dynamics +
              "&firstLetter=" +
              this.firstLetter +
              "&search=" +
              this.search +
              "&code=" +
              this.code +
              "&order=" +
              this.order,
          )
          .then(
            function (response) {
              // handle success
              this.searchResults.push(response.data);
              this.searchResults = this.searchResults.flat();
              this.searching = false;
              this.skip = this.skip + response.data.length;
            }.bind(this),
          )
          .catch(
            function (error) {
              // handle error
              console.error(error);
              this.emit.emit("systemMessage", {
                message: error.response.data.message,
                title: "Error! Failed to search indicators",
                timeout: -1,
                colour: "red",
              });
            }.bind(this),
          );
      }
    },
    lazyLoad: function (event) {
      if (
        event.target.offsetHeight + event.target.scrollTop >=
        event.target.scrollHeight - 1000
      ) {
        this.doSearch();
      }
    },
  },
  watch: {
    computedMetaDataDialog: {
      handler() {
        if (this.computedMetaDataDialog) {
          const elHtml = document.getElementsByTagName("html")[0];
          elHtml.style.overflowY = "hidden";
        } else {
          const elHtml = document.getElementsByTagName("html")[0];
          elHtml.style.overflowY = null;
        }
      },
      immediate: true,
    },
    viewInfoDialog: {
      handler() {
        this.updateMetadataArray = [];
        this.multipleMetadataSelectList = [];
        this.editMode = false;
      },
      immediate: true,
    },
  },
};
</script>

<style scoped>
.editIcon {
  margin-left: 5px;
  font-size: 18px;
  cursor: pointer;
}

.moreInfo {
  color: #363636;
  text-decoration: underline;
  cursor: pointer;
}

.progressText {
  width: 100%;
  text-align: center;
  font-size: 14px;
}

.progress {
  top: 10vh;
  width: 30vw;
  left: 35vw;
}

.custom-loader {
  animation: loader 1s infinite;
  display: flex;
}

@-moz-keyframes loader {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@-webkit-keyframes loader {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@-o-keyframes loader {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes loader {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

.v-card.on-hover.theme--dark {
  background-color: rgba(#fff, 0.8);
}

.v-card__text {
  color: #000;
}
</style>
