<template>
  <!-- class="ma-0 pa-0" fill-height -->
  <div>Deploy Deploy</div>
</template>

<script>
// import FormsInputsAndControls from '@/components/formsInputsAndControls'

export default {
  name: "NAME",
  data: () => ({
    var1: "val1",
    var2: "val2",
    array: [],
    object: {},
  }),
  components: {
    // FormsInputsAndControls
  },
  computed: {
    //widget: {
    //  get() {
    //    return this.$store.state.widget;
    //  },
    //  set(value) {
    //    this.$store.commit("setWidget", value);
    //  },
    //},
  },
  props: {
    model: null,
    item: {},
    disable: {
      type: Boolean,
      required: false,
      default: false,
    },
    relatedModelResults: {},
  },
  mounted() {
    // this.$emit('mainComponentLoaded', this.modelName, this.subHeader, this.searches, '')
  },
  methods: {
    test() {
      // console.log('this is a test')
    },
  },
  watch: {
    selectedItem: {
      handler() {
        // this.$emit('selectedItem', this.selectedItem)
      },
      deep: true,
    },
  },
};
</script>

<style scoped></style>
