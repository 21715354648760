<template>
  <!-- if the width is smaller than an average IPad mini -->
  <div v-if="screenWidth < 760 && !choice">
    <v-dialog v-model="dialog" transition="dialog-bottom-transition" fullscreen>
      <v-card>
        <v-toolbar
          :style="
            'background-color: ' + $store.state.config.siteConfig.toolbar_colour
          "
        >
          <v-btn
            class="dynamicTextColour"
            icon="mdi-close"
            @click="dialog = false"
          ></v-btn>

          <v-toolbar-title class="dynamicTextColour"
            >Device not supported</v-toolbar-title
          >
        </v-toolbar>

        <v-card class="pa-0 h-100">
          <v-card-actions class="pa-0 h-100">
            <v-card-text class="pa-0 px-4">
              <v-card-actions class="justify-center">
                <v-avatar
                  size="140"
                  color="#BFE0F8"
                  class="d-flex justify-center align-center"
                >
                  <v-icon
                    icon="mdi-cellphone-information "
                    color="#093F6B"
                    size="74"
                  />
                </v-avatar>
              </v-card-actions>

              <v-card-actions
                class="text-subtitle-1 font-weight-medium justify-center pt-10 pb-1"
              >
                It looks like you're using a mobile device!
              </v-card-actions>

              <v-card-actions
                class="text-body-2 pt-2 pb-10 font-weight-medium text-center"
                style="color: #7f7f7f"
              >
                Local Insight was not made for small screens. To get the most
                out of our place-based data platform, we recommend switching
                over to a desktop or laptop.
              </v-card-actions>

              <v-card-actions>
                <v-btn
                  @click="continueOnMobile"
                  color="info"
                  width="100%"
                  variant="elevated"
                  >continue on mobile anyway</v-btn
                >
              </v-card-actions>
            </v-card-text>
          </v-card-actions>
        </v-card>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import { useDisplay } from "vuetify";
import { setCookie } from "../mixins/SetCookie";
import { getCookie } from "../mixins/GetCookie";

export default {
  data: () => ({
    dialog: true,
    choice: false,
    screenWidth: useDisplay().width,
  }),
  props: {
    isMobile: {
      type: Boolean,
      default: false,
    },
  },
  mounted() {
    // get user's previously saved choice
    this.choice = getCookie("continueOnMobile") === "true";
  },
  methods: {
    continueOnMobile() {
      // remember choice for the session
      setCookie("continueOnMobile", "true");
      this.dialog = false;
    },
  },
};
</script>
<style scoped></style>
