<template>
  <div class="text-center">
    <v-dialog v-model="computedSettingsDialog" max-width="600px" scrollable>
      <v-card>
        <v-toolbar
          :style="
            'background-color: ' +
            this.$store.state.config.siteConfig.toolbar_colour
          "
          class="text-h6"
        >
          <v-btn
            icon
            dark
            @click="computedSettingsDialog = false"
            aria-label="Close"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title> Settings </v-toolbar-title>
        </v-toolbar>
        <v-container>
          <v-card-text>
            <p>
              You can choose whether the colours on the map show all areas, or
              'hotspot' areas only
            </p>
            <v-radio-group v-model="computedHotspotFlag">
              <v-radio value="all" tabindex="-1">
                <template v-slot:label>
                  <b
                    class="radioLabel"
                    tabindex="0"
                    @keydown.enter="$event.target.click()"
                    >Map All Areas</b
                  >
                </template>
              </v-radio>
              <v-radio value="hotspot" tabindex="-1">
                <template v-slot:label>
                  <b
                    class="radioLabel"
                    tabindex="0"
                    @keydown.enter="$event.target.click()"
                    >Map Hotspots Only</b
                  >
                </template>
              </v-radio>
            </v-radio-group>
            <p>
              <b>All areas:</b> The colours on the map will be based on grouping
              all areas across
              {{
                this.$store.state.config.customClientConfig.national_comparator
                  .country_name
              }}
              into 20% bands. So the top 20% of areas on an indicator are shaded
              dark red, the next 20% shaded light red, and so on. However, in
              some views, the whole map may be a single colour, due to all areas
              in view being in the top (or bottom) 20%.
            </p>
            <p>
              <b>Hotspot areas:</b> The colours on the map will be based on the
              top 20% of areas across
              {{
                this.$store.state.config.customClientConfig.national_comparator
                  .country_name
              }}
              only. The 5 colour bands are based on the top 1%, 1-5%, 5-10%,
              10-15% and 15-20%. Other areas will be unshaded.
            </p>
          </v-card-text>
        </v-container>
        <div v-if="hasBoundary">
          <v-divider></v-divider>
          <v-switch
            label="Show Group Boundary"
            v-model="computedboundaryFlag"
            inset
            color="primary"
            class="boundary-switch"
          ></v-switch>
        </div>
        <v-divider></v-divider>
        <v-card-actions>
          <v-card-subtitle
            v-if="this.$store.state.displayOnsSource"
            class="source"
          >
            <p>
              Source: Office for National Statistics licensed under the Open
              Government Licence v.3.0
            </p>
            <p>
              Contains OS data © Crown copyright and database right
              {{ year }}
            </p>
          </v-card-subtitle>
          <v-spacer></v-spacer>
          <v-btn
            tile
            color="error"
            variant="elevated"
            @click="computedSettingsDialog = false"
            aria-label="close"
          >
            close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  name: "MAPSETTINGS",
  data: () => ({}),
  computed: {
    hasBoundary() {
      return this.$store.getters.customClientConfig.has_boundary;
    },
    year() {
      return new Date().getFullYear();
    },
    //a computed to tell if !hasDefaultView or resettingDefaultView
    computedDisabledReset: function () {
      return !this.hasDefaultView || this.resettingDefaultView;
    },
    computedHotspotFlag: {
      get() {
        return this.hotspotFlag;
      },
      set(val) {
        this.$emit("update:hotspotFlag", val);
      },
    },
    computedboundaryFlag: {
      get() {
        return this.boundaryFlag;
      },
      set(val) {
        this.$emit("update:boundaryFlag", val);
      },
    },
    computedSettingsDialog: {
      get() {
        return this.mapSettingsDialog;
      },
      set(value) {
        this.$emit("update:mapSettingsDialog", value);
      },
    },
  },
  components: {
    // FormsInputsAndControls
  },
  props: {
    mapSettingsDialog: {
      type: Boolean,
      required: true,
      default: false,
    },
    boundaryFlag: {
      type: Boolean,
      required: true,
      default: false,
    },
    hotspotFlag: {
      type: String,
      required: true,
    },
  },
  mounted() {},
  methods: {},
  watch: {},
};
</script>

<style scoped>
.radioLabel {
  color: black;
  font-size: 15px;
  font-family: Rubik, Helvetica, sans-serif;
  font-weight: bold;
}

.source {
  font-size: 10px;
}

.boundary-switch {
  margin: 15px 0px 0px 30px;
  padding: 0px;
}
</style>
