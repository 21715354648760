<template>
  <DynamicDialog
    :show="dialog"
    @dialogOk="setDateRange()"
    @dialogCancel="dialog = false"
    @update:showDyamicDialog="(state) => (dialog = state)"
    max-width="700"
    min-height="400"
    :okBtnDisabled="!startDate?.year || !endDate?.year"
    okBtnColor="success"
    cancelBtnColor="none"
    cancelBtnVariant="text"
  >
    <template v-slot:title>Select date range</template>
    <template v-slot:content>
      <v-row justify="center">
        <v-col cols="6" class="text-left">
          <YearMonthPicker v-model="startDate" headerText="FROM" />
        </v-col>
        <v-divider vertical />
        <v-col cols="6" class="text-left">
          <YearMonthPicker v-model="endDate" headerText="TO" />
        </v-col>
      </v-row>
    </template>
    <template v-slot:okBtnTitle>set date range</template>
  </DynamicDialog>
</template>
<script>
import { systemMessages } from "@/mixins/SystemMessages";
import DynamicDialog from "@/components/DynamicDialog.vue";
import YearMonthPicker from "@/components/YearMonthPicker.vue";

export default {
  name: "DateRangePicker",
  mixins: [systemMessages],
  data: () => ({
    months: {
      1: "Jan",
      2: "Feb",
      3: "Mar",
      4: "Apr",
      5: "May",
      6: "Jun",
      7: "Jul",
      8: "Aug",
      9: "Sep",
      10: "Oct",
      11: "Nov",
      12: "Dec",
    },
    startDate: null,
    endDate: null,
  }),
  components: {
    DynamicDialog,
    YearMonthPicker,
  },
  props: {
    dialogProp: {
      type: Boolean,
      required: true,
      default: false,
    },
    // check if start date is greater than end date
    validateDateRange: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
  computed: {
    dialog: {
      get() {
        return this.dialogProp;
      },
      set(val) {
        this.$emit("update:dialogProp", val);
      },
    },
  },
  methods: {
    /**
     * Serves the date range up the the parent component
     * @example { dateSelection: "2022-06 -> 2024-08", label: "Jun 2022 - Aug 2024" }
     */
    setDateRange() {
      // need validation?
      if (this.validateDateRange) {
        const isStartGreaterThanEnd =
          this.startDate.year > this.endDate.year ||
          (this.startDate.year === this.endDate.year &&
            this.startDate.month > this.endDate.month);

        if (isStartGreaterThanEnd) {
          this.warningPill({
            title: "Start date cannot be greater than end date",
            icon: "mdi-information",
          });
          return;
        }
      }

      const dateSelection = `${this.startDate.sqlVersion} -> ${this.endDate.sqlVersion}`;
      const label = `${this.startDate.label} - ${this.endDate.label}`;

      this.$emit("setRange", { dateSelection, label });

      this.dialog = false;
    },
  },
  watch: {
    dialog(open) {
      if (open) {
        // reset these
        this.startDate = null;
        this.endDate = null;
      }
    },
  },
};
</script>
<style scoped></style>
