<template>
  <v-card-text
    v-if="
      viewInfo.quintiles.q1_min !== null &&
      viewInfo.quintiles.q1_min != 'loading'
    "
    style="margin-right: 10px"
    class="ma-0 pa-0 text-left"
  >
    <v-icon :color="'#' + colourScheme[0]" style="margin-right: 10px"
      >mdi-square
    </v-icon>
    <span style="margin-top: 25px">
      {{ getLegendData("q1") }}
    </span>
    <br />
    <v-icon :color="'#' + colourScheme[1]" style="margin-right: 10px"
      >mdi-square
    </v-icon>
    <span style="margin-top: 25px">
      {{ getLegendData("q2") }}
    </span>
    <br />
    <v-icon :color="'#' + colourScheme[2]" style="margin-right: 10px"
      >mdi-square
    </v-icon>
    <span style="margin-top: 25px">
      {{ getLegendData("q3") }}
    </span>
    <br />
    <v-icon :color="'#' + colourScheme[3]" style="margin-right: 10px"
      >mdi-square
    </v-icon>
    <span style="margin-top: 25px">
      {{ getLegendData("q4") }}
    </span>
    <br />
    <v-icon :color="'#' + colourScheme[4]" style="margin-right: 10px"
      >mdi-square
    </v-icon>
    <span style="margin-top: 25px">
      {{ getLegendData("q5") }}
    </span>
  </v-card-text>
</template>
<script>
import { formatDataValue } from "@/mixins/FormatDataValue";

export default {
  name: "MapQuintiles",
  data: () => ({}),
  props: {
    viewInfo: {
      type: Object,
      default: () => {
        return {};
      },
      required: true,
    },
    colourScheme: {
      type: Array,
      default: () => [],
      required: true,
    },
  },
  methods: {
    getLegendData(entry) {
      if (this.viewInfo.indicatorInfo) {
        let min = entry + "_min";
        let max = entry + "_max";
        let pre = this.viewInfo.indicatorInfo.prefix
          ? this.viewInfo.indicatorInfo.prefix
          : "";
        let suf = this.viewInfo.indicatorInfo.suffix
          ? this.viewInfo.indicatorInfo.suffix
          : "";

        if (
          this.viewInfo.quintiles[min] !== null &&
          this.viewInfo.quintiles[max] !== null
        ) {
          return (
            "" +
            pre +
            formatDataValue(
              this.viewInfo.quintiles[min],
              this.viewInfo.indicatorInfo.decimal_places,
              suf === "%" ? 100 : false, //cap percentages at 100%
            ) +
            suf +
            " - " +
            pre +
            formatDataValue(
              this.viewInfo.quintiles[max],
              this.viewInfo.indicatorInfo.decimal_places,
              suf === "%" ? 100 : false, //cap percentages at 100%
            ) +
            suf
          );
        }
        return "-";
      } else {
        return "-";
      }
    },
  },
};
</script>
<style scoped></style>
