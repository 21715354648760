<script>
import { Intercom, update, shutdown } from "@intercom/messenger-js-sdk";

export default {
  name: "IntercomWidget",
  /** renderless component Intercom lib injects widget */
  render() {
    return this.$slots.default;
  },
  mounted() {
    this.setIntercomEnabled();
    this.initMessenger(); //start intercom when component called
    this.emit.on("intercom:shutdown", this.shutdownMessenger); //list for call to end session
  },
  data() {
    return {
      environments: ["stg", "prod"], //add VUE_APP_ENVIRONMENT envs here to enable intercom e.g. dev
      excludedRoutes: ["/login", "/", "/map", "/discovery-tool"], //add route paths here where intercom should NOT appear
      intercomEnabled: false,
    };
  },
  computed: {
    //fetch app config (user/company details) from vuex
    config: {
      get() {
        return this.$store.state.config;
      },
    },
    //check whether a user is logged in
    userLoggedIn: {
      get() {
        return this.config.userProfile !== undefined;
      },
    },
    //show or hide base on route and whether a user is logged in
    hideMessenger: {
      get() {
        /** temporary restriction for testing in production for ocsi users - to be removed */
        const ocsiUser = /@ocsi.co.uk$/i.test(this.config.userProfile?.email);

        return (
          this.excludedRoutes.includes(this.$route.path) ||
          !this.userLoggedIn ||
          !ocsiUser
        );
      },
    },
  },
  watch: {
    //watch for vue route changes
    $route() {
      this.updateMessenger();
    },
  },
  methods: {
    /** initialise the intercom messenger integration and show or hide it */
    initMessenger() {
      if (this.intercomEnabled) {
        Intercom({
          app_id: process.env.VUE_APP_INTERCOM_APP_ID,
          region: "eu",
          hide_default_launcher: this.hideMessenger,
        });

        if (this.userLoggedIn) {
          this.updateMessenger();
        }
      }
    },
    /** update the messenger instance with user and company details */
    updateMessenger() {
      if (this.intercomEnabled) {
        update({
          hide_default_launcher: this.hideMessenger,
          email: this.config.userProfile.email,
          name: this.config.userProfile.name,
          created_at: this.config.userProfile.created_at,
          company: {
            type: "company",
            id: this.config.userProfile.client_id,
            company_id: this.config.userProfile.client_id,
            name: this.config.customClientConfig.client_name,
          },
        });
      }
    },
    /** end the messenger instance session */
    shutdownMessenger() {
      if (this.intercomEnabled) {
        shutdown();
      }
    },
    /** set enabled/disabled flag based on current environment */
    setIntercomEnabled() {
      this.intercomEnabled = this.environments.includes(
        process.env.VUE_APP_ENVIRONMENT,
      );
    },
  },
};
</script>
