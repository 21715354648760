<template>
  <v-card class="my-4 main">
    <!-- Header -->
    <v-card-title class="cardTitle border-b-thin border-b-md pa-0 pr-4">
      <v-card-actions>
        <v-icon :color="areasValid ? 'green' : '#FB8C00'" class="ma-2">
          {{ areasValid ? "mdi-check-circle" : "mdi-alert" }}
        </v-icon>
        <div>
          Areas
          {{
            selectedCustomAreas?.length ? `(${selectedCustomAreas.length})` : ""
          }}
        </div>
      </v-card-actions>
    </v-card-title>
    <v-card-text
      v-if="!selectedCustomAreas?.length"
      class="text-left pa-4 pb-0"
    >
      <!-- Alert -->
      <v-alert color="info" icon="mdi-information">
        <div style="font-weight: 500">No areas defined</div>
        <div>Add at least one area to filter the indicator data by</div>
      </v-alert>
    </v-card-text>
    <v-card-text v-else class="pa-0">
      <v-alert
        v-if="OA_sum > 3325"
        color="warning"
        icon="mdi-information"
        class="text-left ma-4"
      >
        <div style="font-weight: 500">Output Area limit exceeded</div>
        <div>
          Your query includes data for {{ OA_sum.toLocaleString() }} Output
          Areas, the maximum allowed is 3,325. Please adjust your area selection
          to proceed.
        </div>
      </v-alert>
      <v-card-text
        v-for="area in selectedCustomAreas"
        :key="area.id"
        class="px-4 py-2 text-left border-b-thin d-flex align-center"
      >
        <div>
          <div class="text-body-2 font-weight-regular">{{ area.name }}</div>
          <div class="text-caption font-weight-light">
            {{ area.category_name }} •
            {{ area.OA_count.toLocaleString() }} Output Areas included
          </div>
        </div>
        <v-spacer />
        <div>
          <v-btn
            icon="mdi-delete"
            @click="
              areaToRemove = area;
              removeAreaDialog = true;
            "
            color="error"
            variant="text"
            size="24"
          />
        </div>
      </v-card-text>
    </v-card-text>
    <v-card-actions class="pa-4 pt-0">
      <v-spacer />
      <v-btn
        class="mt-4 create-api-key-btn"
        variant="elevated"
        prepend-icon="mdi-plus"
        color="#0e5b99"
        @click="customAreaSelectorDialog = true"
        rounded="0"
      >
        add area
      </v-btn>
    </v-card-actions>

    <CustomAreaSelector
      v-model:dialog="customAreaSelectorDialog"
      @selectedCustomAreas="editCustomAreas"
    />

    <DynamicDialog
      :show="removeAreaDialog"
      @dialogOk="removeArea()"
      @dialogCancel="
        removeAreaDialog = false;
        areaToRemove = null;
      "
      @update:showDyamicDialog="(state) => (removeAreaDialog = state)"
      max-width="420"
      cancelBtnColor="none"
      minHeight="100"
      okBtnColor="error"
      cancelBtnVariant="text"
    >
      <template v-slot:title>Remove area</template>
      <template v-slot:content>
        <v-card-actions class="pa-0 text-center align-center">
          Are you sure you want to remove this area from the query
        </v-card-actions>
      </template>
      <template v-slot:okBtnTitle>remove</template>
    </DynamicDialog>
  </v-card>
</template>

<script>
import CustomAreaSelector from "@/components/CustomAreaSelector.vue";
import DynamicDialog from "@/components/DynamicDialog.vue";
import { systemMessages } from "@/mixins/SystemMessages";

export default {
  name: "DatastoreQueryBuilderAreas",
  mixins: [systemMessages],
  data: () => ({
    customAreaSelectorDialog: false,
    fetchingCustomAreaOAs: false,
    removeAreaDialog: false,
    areaToRemove: null,
  }),
  props: {
    selectedAreas: {
      type: Array,
      default: () => [],
      required: true,
    },
  },
  components: {
    CustomAreaSelector,
    DynamicDialog,
  },
  computed: {
    selectedCustomAreas: {
      get() {
        return this.selectedAreas;
      },
      set(val) {
        this.$emit("update:selectedAreas", val);
      },
    },
    areasValid() {
      return (
        this.selectedCustomAreas?.length &&
        this.selectedCustomAreas.length < 3325 &&
        this.OA_sum <= 3325
      );
    },
    OA_sum() {
      let sum = 0;

      if (this.selectedCustomAreas?.length) {
        this.selectedCustomAreas.forEach((area) => {
          sum += area.OA_count;
        });
      }

      return sum;
    },
  },
  methods: {
    async editCustomAreas(selectedAreas) {
      try {
        this.fetchingCustomAreaOAs = true;

        const response = await this.$axios.post(
          "/datastore/query-builder/custom-areas-oas",
          { areaIDs: selectedAreas },
        );

        this.selectedCustomAreas = response.data;
      } catch {
        this.errorPill({
          title: "Failed to fetch custom areas. Try later",
          timeout: 3000,
        });
      } finally {
        this.fetchingCustomAreaOAs = false;
      }
    },

    removeArea() {
      this.selectedCustomAreas = this.selectedCustomAreas.filter(
        (a) => a.id !== this.areaToRemove.id,
      );
      this.removeAreaDialog = false;
    },
  },
};
</script>

<style scoped>
/* Add your styles here */
</style>
