<template>
  <div id="headerModule">
    <!--  Header  -->
    <v-card
      :style="{
        padding: padding,
      }"
      elevation="3"
      class="d-flex justify-center align-center"
      :class="{ 'pb-0': modelTabs?.length }"
      id="headerCard"
    >
      <v-card-text :style="{ width: '100%', maxWidth: width }" class="pa-0">
        <!-- Apply the width to max widht only so we can handle small devices too -->
        <v-card-actions>
          <v-card-actions v-if="icon" class="mr-1">
            <v-icon size="23" :icon="`mdi-${icon}`" />
          </v-card-actions>
          <v-card-actions class="px-0">
            <div v-if="title" style="font-size: 24px; font-weight: 600">
              {{ title }}
            </div>
            <i class="pl-2" style="font-size: 14px">
              {{ description }}
            </i>
          </v-card-actions>
        </v-card-actions>

        <!-- Tab headers -->
        <v-card-actions v-if="modelTabs?.length" class="pb-0">
          <v-tabs v-model="activeTab" color="primary">
            <v-tab
              v-for="(tab, index) in modelTabs"
              :key="index"
              :value="tab.value"
            >
              <div style="font-weight: 500">{{ tab?.title }}</div>
              <v-icon v-if="tab.icon" :icon="`mdi-${tab.icon}`" class="ml-2" />
            </v-tab>
          </v-tabs>
        </v-card-actions>
      </v-card-text>
    </v-card>

    <!--  Content of tabs  -->
    <v-card
      v-if="modelTabs?.length"
      class="d-flex justify-center mt-2 pa-0 w-100"
      elevation="0"
      style="background-color: #f9f9f9"
      :style="{
        overflowY: 'auto',
        height: `${pageHeight - 190}px`,
        overflowX: 'hidden',
        scrollbarGutter: 'stable',
      }"
      @scroll="handleScroll($event)"
    >
      <v-card-text
        class="pa-0 pr-2"
        :style="{
          maxWidth: width,
        }"
      >
        <v-tabs-window v-model="activeTab">
          <v-tabs-window-item
            v-for="(tab, index) in modelTabs"
            :key="index"
            :value="tab.value"
          >
            <slot :name="tab.value" />
          </v-tabs-window-item>
        </v-tabs-window>
      </v-card-text>
    </v-card>
  </div>
</template>
<script>
import { useDisplay } from "vuetify";

export default {
  name: "HeaderModule",
  props: {
    title: String,
    icon: String,
    description: String,
    width: {
      type: String,
      default: "100%",
    },
    padding: {
      type: String,
      default: "10px",
    },
    // example: [ { title: "Catalogue", value: "catalogue", icon: "view-dashboard", link: "https://example.com" }, ]
    tabs: {
      type: Array,
      default: () => [],
    },
    selectedTabRoute: {
      type: String,
      default: null,
    },
    //
    defaultTab: {
      type: String,
      default: null,
      required: false,
    },
  },
  data: () => ({
    previousTab: null,
    pageHeight: useDisplay().height,
  }),
  mounted() {
    // default tab
    if (this.defaultTab && this.tabs?.length) {
      this.activeTab = this.defaultTab;
    }
  },
  computed: {
    /**
     * Represents the currently active tab.
     * The property is synced with the URL
     */
    activeTab: {
      get() {
        return (
          this.modelTabs.find(
            (t) => t.route === (this.$route.params?.selectedTabRoute || ""),
          )?.value || ""
        );
      },
      set(val) {
        const newTabRoute =
          this.modelTabs.find((t) => t.value === val)?.route || val;

        // navigate to the new route and keep any queries
        this.$router.replace({
          params: { selectedTabRoute: newTabRoute },
          query: { ...this.$route.query },
        });
      },
    },
    modelTabs: {
      get() {
        return this.tabs;
      },
      set(val) {
        this.$emit("update:tabs", val);
      },
    },
  },
  methods: {
    /** if @scrollTabEvent emit is passed then we can trigger this when user scrolls to the bottom of the tab.
     * This is useful for lazy loading content in tabs
     */
    handleScroll(event) {
      // if  is passed then triger that up to the parent
      if (this.$attrs["onScrollTabEvent"]) {
        if (
          event.target.offsetHeight + event.target.scrollTop >=
          event.target.scrollHeight - 200
        ) {
          this.$emit("scrollTabEvent", this.activeTab);
        }
      }
    },
  },
  watch: {
    /** watch active tab changes and open links or update browser URL if requried */
    activeTab(newVal, oldVal) {
      const newTab = this.modelTabs.find((t) => t.value === newVal);

      // check if this is a link tab and redirect to the link
      if (newTab?.link) {
        window.open(newTab.link, "_blank");

        // go back to the previous tab
        this.activeTab = this.modelTabs.find((t) => t.value === oldVal).value;
        return;
      }
    },
  },
};
</script>

<style scoped>
* {
  color: #4d4d4d;
  font-family: "Inter", sans-serif;
}
</style>
