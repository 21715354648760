<template>
  <span>
    <v-dialog v-model="viewInfoDialog" width="710" scrollable>
      <v-card>
        <v-toolbar
          :style="
            'background-color: ' +
            this.$store.state.config.siteConfig.toolbar_colour
          "
        >
          <v-toolbar-title class="text-left"
            >More Info</v-toolbar-title
          ></v-toolbar
        >
        <v-card-text v-if="loadingMoreInfo" style="height: 500px">
          <div class="text-center" style="margin-top: 35%">
            <v-progress-circular
              :size="120"
              :width="3"
              color="#51627C"
              indeterminate
              >Loading</v-progress-circular
            >
          </div>
        </v-card-text>
        <v-card-text v-else-if="editMode" style="height: 500px" class="pa-4">
          <v-card-actions>
            <v-row justify="space-around">
              <v-date-picker
                header="From Date"
                width="330"
                v-model="dateFrom"
                color="#51627C"
                elevation="24"
              ></v-date-picker>
              <v-date-picker
                header="To Date"
                width="330"
                v-model="dateTo"
                color="#51627C"
                elevation="24"
              ></v-date-picker>
            </v-row>
          </v-card-actions>
        </v-card-text>
        <v-card-text v-else style="height: 500px">
          <v-card-text v-for="(item, index) in this.selectedItem" :key="index">
            <strong>{{ index }}</strong
            ><br />{{ item }}
          </v-card-text>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-select
            v-model="selectedOption"
            :items="computedSelectionOptions"
            label="Selection Options"
            item-title="label"
            :return-object="true"
            variant="outlined"
            density="compact"
            rounded="0"
            class="mt-6 mr-1"
            clearable
            style="width: 70%"
            @update:modelValue="handleSelectedOption(selectedOption)"
          ></v-select>
          <v-spacer></v-spacer>
          <v-btn
            v-if="dynTextMode"
            :disabled="!selectedOption ? true : false"
            color="success"
            variant="elevated"
            tile
            @click="copyIndicatorText()"
            aria-label="copy"
          >
            copy
          </v-btn>
          <v-btn
            v-else
            :disabled="!selectedOption ? true : false"
            color="success"
            variant="elevated"
            tile
            @click="addIndicator()"
            aria-label="save"
          >
            save
          </v-btn>
          <v-btn
            color="primary"
            variant="elevated"
            tile
            @click="
              viewInfoDialog = false;
              editMode = false;
            "
            aria-label="close"
          >
            close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="computedMetaDataDialog"
      fullscreen
      hide-overlay
      persistent
      scrollable
    >
      <v-card>
        <v-toolbar
          :style="
            'background-color: ' + $store.state.config.siteConfig.toolbar_colour
          "
        >
          <v-btn
            icon
            dark
            @click="computedMetaDataDialog = false"
            aria-label="Close"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>Indicators</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-text-field
              type="text"
              v-model="code"
              label="Search Indicator Code"
              variant="outlined"
              density="compact"
              rounded="0"
              class="fields mt-3 mr-1"
              clearable
              bg-color="#fff"
              style="width: 300px"
              @keyup="
                search = null;
                firstLetter = null;
              "
              @keyup.enter="searchForIndicator"
              autocomplete="off"
            ></v-text-field>
            <v-text-field
              type="text"
              v-model="search"
              label="Search Indicator Name"
              variant="outlined"
              density="compact"
              rounded="0"
              class="fields mt-3 mr-1"
              clearable
              bg-color="#fff"
              style="width: 300px"
              @keyup="
                code = null;
                firstLetter = null;
              "
              @keyup.enter="searchForIndicator"
              autocomplete="off"
            ></v-text-field>
            <v-select
              v-model="firstLetter"
              :items="letters"
              label="First Letter"
              variant="outlined"
              density="compact"
              rounded="0"
              class="fields mt-3 mr-1"
              clearable
              bg-color="#fff"
              style="width: 140px"
              @change="
                code = null;
                search = null;
              "
            ></v-select>
            <v-select
              v-model="live"
              :items="[
                { title: 'Live', value: true },
                { title: 'Non-Live', value: false },
              ]"
              label="Live?"
              variant="outlined"
              density="compact"
              rounded="0"
              class="fields mt-3 mr-1"
              clearable
              bg-color="#fff"
              style="width: 100px"
            ></v-select>
            <v-select
              v-model="dynamics"
              :items="[
                { title: 'Dynamic', value: true },
                { title: 'Non-Dynamic', value: false },
              ]"
              label="Dynamic?"
              variant="outlined"
              density="compact"
              rounded="0"
              class="fields mt-3 mr-1"
              clearable
              bg-color="#fff"
              style="width: 190px"
            ></v-select>
            <v-select
              v-model="order"
              :items="[
                { title: 'Date Desc', value: 'dd' },
                { title: 'Date Asc', value: 'da' },
                { title: 'Name Desc', value: 'cd' },
                { title: 'Name Asc', value: 'ca' },
              ]"
              label="Order By"
              variant="outlined"
              density="compact"
              rounded="0"
              class="fields mt-3 mr-1"
              bg-color="#fff"
              style="width: 150px"
            ></v-select>
          </v-toolbar-items>
          <v-btn
            rounded="0"
            variant="elevated"
            :loading="searching"
            :disabled="searching"
            color="success"
            @click="searchForIndicator"
            aria-label="Search"
          >
            Search
            <template v-slot:loader>
              <span class="custom-loader">
                <v-icon light>mdi-cached</v-icon>
              </span>
            </template>
          </v-btn>
        </v-toolbar>
        <v-card-text style="height: 95vh" @scroll="lazyLoad">
          <!-- loading UX -->
          <v-card
            v-if="searchResults.length === 0"
            tile
            elevation="0"
            class="progress"
          >
            <v-card-actions v-if="searching">
              <div class="progressText">
                <v-progress-circular
                  :size="200"
                  :width="3"
                  color="#51627C"
                  indeterminate
                  >Searching</v-progress-circular
                >
              </div>
            </v-card-actions>
            <v-card-actions v-else>
              <div class="progressText">No Search Results</div>
            </v-card-actions>
          </v-card>
          <!-- results -->
          <v-hover
            v-slot="{ hover }"
            v-else
            v-for="(item, index) in searchResults"
            :key="index"
          >
            <v-card
              :elevation="hover ? 4 : 1"
              :class="{ 'on-hover': hover }"
              class="mx-auto mt-2"
            >
              <v-card-actions>
                <v-card-text>
                  <v-row align="center" class="mx-0">
                    <div style="width: 30%; margin-right: 10px">
                      <strong>Name: </strong><br /><span
                        class="moreInfo"
                        title="Click for More Information"
                        @click="getInfo(item)"
                        >{{ item.indicator_name }}</span
                      >
                    </div>
                    <div style="width: 15%; margin-right: 10px">
                      <strong>Code: </strong><br />{{ item.indicator_code }}
                    </div>
                    <div style="width: 10%">
                      <strong>Date: </strong><br />{{ item.date }}
                    </div>
                    <div style="width: 10%">
                      <strong>Live: </strong><br />{{ item.live }}
                    </div>
                    <div style="width: 15%">
                      <strong>Numerator: </strong><br />{{ item.numerator_id }}
                    </div>
                    <div style="width: 15%">
                      <strong>Denominator: </strong><br />{{
                        item.denominator_id
                      }}
                    </div>
                  </v-row>
                </v-card-text>
              </v-card-actions>
            </v-card>
          </v-hover>
        </v-card-text>
      </v-card>
    </v-dialog>
  </span>
</template>

<script>
import { formatDate, formatToSQLDate } from "@/mixins/FormatDate";
import { copyToClipboard } from "@/mixins/CopyToClipboard";
export default {
  name: "ADDMETATABLE",
  data: () => ({
    formatDate: formatDate,
    formatToSQLDate: formatToSQLDate,
    order: "dd",
    live: null,
    skip: 0,
    take: 100,
    code: null,
    search: null,
    firstLetter: null,
    dynamics: null,
    letters: [
      "A",
      "B",
      "C",
      "D",
      "E",
      "F",
      "G",
      "H",
      "I",
      "J",
      "K",
      "L",
      "M",
      "N",
      "O",
      "P",
      "Q",
      "R",
      "S",
      "T",
      "Y",
      "V",
      "W",
      "X",
      "Y",
      "Z",
    ],
    searching: false,
    searchResults: [],
    viewInfoDialog: false,
    loadingMoreInfo: true,
    selectedItem: null,
    selectionOptions: [],
    selectedOption: null,
    supportsClipboard: false,
    dateFrom: null,
    dateTo: null,
    editMode: false,
  }),
  components: {},
  props: {
    addMetaTableDialog: {
      type: Boolean,
      required: true,
      default: false,
    },
    addDomainHTMLTableColTwo: {
      type: Boolean,
      required: false,
      default: false,
    },
    indicatorHTMLTableRef: {
      type: String,
      required: false,
      default: null,
    },
    chartRef: {
      type: String,
      required: false,
      default: null,
    },
    dynTextMode: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  computed: {
    reportThemeObject: {
      get() {
        return this.$store.state.reportThemeObject;
      },
      set(value) {
        this.$store.commit("setReportThemeObject", value);
      },
    },
    computedMetaDataDialog: {
      get() {
        return this.addMetaTableDialog;
      },
      set(val) {
        this.$emit("update:addMetaTableDialog", val);
      },
    },
    computedSelectionOptions() {
      return this.dynTextMode
        ? this.selectionOptions.filter(
            (obj) => !obj.label.includes("Select ALL time series data"),
          )
        : this.selectionOptions;
    },
  },
  created() {
    if (navigator.clipboard) {
      this.supportsClipboard = true;
    }
  },
  mounted() {},
  unmounted() {},
  methods: {
    addIndicator() {
      // Fill in date selection if range is selected
      if (this.dateFrom && this.dateTo) {
        this.selectedOption.dateSelection =
          this.formatToSQLDate(this.dateFrom) +
          "," +
          this.formatToSQLDate(this.dateTo);
      }
      this.reportThemeObject["newIndicator"] = {
        indicatorHTMLTableRef: this.indicatorHTMLTableRef,
        chartRef: this.chartRef,
        indicatorCode: this.selectedOption.indicatorCode,
        indicatorDate: this.selectedOption.dateSelection,
        addDomainHTMLTableColTwo: this.addDomainHTMLTableColTwo,
      };
      this.$emit("updateTheme");
      if (!this.indicatorHTMLTableRef) {
        this.computedMetaDataDialog = false;
        this.viewInfoDialog = false;
      }
      this.editMode = false;
      this.dateFrom = null;
      this.dateTo = null;
      this.selectedOption.dateSelection = null;
      this.selectedOption = null;
    },
    copyIndicatorText() {
      if (
        copyToClipboard(
          this.selectedOption.indicatorCode +
            "." +
            this.selectedOption.dateSelection,
        )
      ) {
        // show a snack bar to pop up for a second to say it’s been copied to their clipboard
        this.emit.emit("systemMessage", {
          title: "The value has been copied to the clipboard",
          message: "",
          timeout: 2000,
          color: "green",
        });
      } else {
        /* clipboard write failed */
        this.emit.emit("systemMessage", {
          title: "The value has NOT been copied to the clipboard",
          message: "Error",
          timeout: -1,
          colour: "red",
        });
      }
    },
    getInfo(item) {
      this.selectedItem = null;
      this.selectedOption = null;
      this.loadingMoreInfo = true;
      this.viewInfoDialog = true;
      this.$axios
        .get("/standard-metadata/" + item.id)
        .then(
          function (response) {
            // handle success
            this.selectedItem = response.data;
            this.getSelectionOptions();
          }.bind(this),
        )
        .catch(
          function (error) {
            // handle error
            console.error(error);
            this.emit.emit("systemMessage", {
              message: error.response.data.message,
              title: "Error! Failed to search indicators",
              timeout: -1,
              colour: "red",
            });
          }.bind(this),
        );
    },
    getSelectionOptions() {
      this.$axios
        .get("/indicators-timeSeries/" + this.selectedItem.indicator_code)
        .then(
          function (response) {
            // handle success
            this.selectionOptions = response.data;
            // remove the date range selection option
            if (this.dynTextMode) {
              this.selectionOptions.shift();
            }
            this.loadingMoreInfo = false;
          }.bind(this),
        )
        .catch(
          function (error) {
            // handle error
            console.error(error);
            this.emit.emit("systemMessage", {
              message: error.response.data.message,
              title: "Error! Failed to find selection options",
              timeout: -1,
              colour: "red",
            });
          }.bind(this),
        );
    },
    searchForIndicator() {
      this.skip = 0;
      this.searchResults = [];
      this.doSearch();
    },
    doSearch() {
      if (!this.searching) {
        this.searching = true;
        this.$axios
          .get(
            "/paginated-metadata-lite?skip=" +
              this.skip +
              "&take=200&live=" +
              this.live +
              "&dynamics=" +
              this.dynamics +
              "&firstLetter=" +
              this.firstLetter +
              "&search=" +
              this.search +
              "&code=" +
              this.code +
              "&order=" +
              this.order,
          )
          .then(
            function (response) {
              // handle success
              this.searchResults.push(response.data);
              this.searchResults = this.searchResults.flat();
              this.searching = false;
              this.skip = this.skip + response.data.length;
            }.bind(this),
          )
          .catch(
            function (error) {
              // handle error
              console.error(error);
              this.emit.emit("systemMessage", {
                message: error.response.data.message,
                title: "Error! Failed to search indicators",
                timeout: -1,
                colour: "red",
              });
            }.bind(this),
          );
      }
    },
    lazyLoad: function (event) {
      if (
        event.target.offsetHeight + event.target.scrollTop >=
        event.target.scrollHeight - 1000
      ) {
        this.doSearch();
      }
    },
    handleSelectedOption: function (selectedOption) {
      // If date range is selected
      if (selectedOption.dateSelection === null) {
        this.editMode = true;
        // Initialise dates to now
        this.dateFrom = null;
        this.dateTo = null;
      }
    },
  },
  watch: {
    computedMetaDataDialog: {
      handler() {
        if (this.computedMetaDataDialog) {
          const elHtml = document.getElementsByTagName("html")[0];
          elHtml.style.overflowY = "hidden";
        } else {
          const elHtml = document.getElementsByTagName("html")[0];
          elHtml.style.overflowY = null;
        }
      },
      immediate: true,
    },
  },
};
</script>

<style scoped>
.moreInfo {
  color: #363636;
  text-decoration: underline;
  cursor: pointer;
}
.progressText {
  width: 100%;
  text-align: center;
  font-size: 14px;
}
.progress {
  top: 10vh;
  width: 30vw;
  left: 35vw;
}
.custom-loader {
  animation: loader 1s infinite;
  display: flex;
}
@-moz-keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
@-webkit-keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
@-o-keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
@keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}

.v-card.on-hover.theme--dark {
  background-color: rgba(#fff, 0.8);
}
.v-card__text {
  color: #000;
}
</style>
