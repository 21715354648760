<template>
  <v-container fluid pa-5 :style="'overflow: auto;'">
    <v-layout row wrap>
      <!-- the parent card/tile -->
      <v-card tile min-height="100%" style="width: 100%">
        <v-card-title class="text-start"
          ><h1 class="h1" id="servicePageTitle">
            Manage Services
          </h1></v-card-title
        >
        <v-card-actions>
          <v-divider></v-divider>
        </v-card-actions>
        <v-tabs v-model="selectedTab">
          <v-tab class="tab-selectors" id="ImportServices" tabindex="0">
            Import/Export Services
          </v-tab>
          <v-tab class="tab-selectors" id="ManageServiceTypes" tabindex="0">
            Manage Service Types
          </v-tab>
        </v-tabs>
        <v-tabs-window v-model="selectedTab">
          <v-tabs-window-item>
            <v-card flat>
              <ImportExportServices @refresh="handleRefreshServiceTypes" />
            </v-card>
          </v-tabs-window-item>
          <v-tabs-window-item>
            <v-card flat>
              <ManagerServiceTypes :refreshServiceTypes="refreshServiceTypes" />
            </v-card>
          </v-tabs-window-item>
        </v-tabs-window>
      </v-card>
    </v-layout>
  </v-container>
</template>

<script>
import ImportExportServices from "@/components/ImportExportServices";
import ManagerServiceTypes from "@/components/ManagerServiceTypes";

export default {
  name: "NAME",
  data: () => ({
    selectedTab: 0,
    refreshServiceTypes: false,
  }),
  components: {
    ImportExportServices,
    ManagerServiceTypes,
  },
  computed: {},
  props: {},
  mounted() {},
  methods: {
    handleRefreshServiceTypes() {
      // turn it off and on again....just nudges the component to refresh
      this.refreshServiceTypes = false;
      this.refreshServiceTypes = true;
    },
  },
  watch: {},
};
</script>

<style scoped>
.tab-selectors {
  font-weight: 550;
}
</style>
