<template>
  <v-container class="pa-0 ma-0" :style="styling">
    <!-- Toolbar -->
    <v-row class="toolbar pa-0 ma-0" align="center" justify="start" dense>
      <v-btn v-if="bold" icon @click="toggleBold">
        <v-icon>mdi-format-bold</v-icon>
      </v-btn>
      <v-btn v-if="italic" icon @click="toggleItalic">
        <v-icon>mdi-format-italic</v-icon>
      </v-btn>
      <v-btn v-if="underline" icon @click="toggleUnderline">
        <v-icon>mdi-format-underline</v-icon>
      </v-btn>
      <v-btn v-if="bulletList" icon @click="toggleBulletList">
        <v-icon>mdi-format-list-bulleted</v-icon>
      </v-btn>
      <v-btn v-if="orderedList" icon @click="toggleOrderedList">
        <v-icon>mdi-format-list-numbered</v-icon>
      </v-btn>
      <v-btn v-if="heading1" icon @click="toggleHeading(1)">
        <v-icon>mdi-format-header-1</v-icon>
      </v-btn>
      <v-btn v-if="heading2" icon @click="toggleHeading(2)">
        <v-icon>mdi-format-header-2</v-icon>
      </v-btn>
      <v-btn v-if="heading3" icon @click="toggleHeading(3)">
        <v-icon>mdi-format-header-3</v-icon>
      </v-btn>
      <v-btn v-if="image" icon @click="addImage">
        <v-icon>mdi-image</v-icon>
      </v-btn>
      <v-btn v-if="link" icon @click="addLink">
        <v-icon>mdi-link</v-icon>
      </v-btn>
      <v-btn v-if="paragraph" icon @click="addParagraph">
        <v-icon>mdi-format-paragraph</v-icon>
      </v-btn>
    </v-row>

    <!-- Editor -->
    <editor-content :editor="editor" class="editor" />
  </v-container>
</template>

<script>
import { onBeforeUnmount, watch } from "vue";
import { useEditor, EditorContent } from "@tiptap/vue-3";
import StarterKit from "@tiptap/starter-kit";
import Underline from "@tiptap/extension-underline";
import Image from "@tiptap/extension-image";
import Link from "@tiptap/extension-link";

export default {
  name: "OCSITiptapEditor",
  components: {
    EditorContent,
  },
  props: {
    styling: {
      type: String,
      required: false,
      default: "",
    },
    content: {
      type: String,
      required: false,
      default: "",
    },
    italic: {
      type: Boolean,
      required: false,
      default: true,
    },
    underline: {
      type: Boolean,
      required: false,
      default: true,
    },
    bulletList: {
      type: Boolean,
      required: false,
      default: true,
    },
    orderedList: {
      type: Boolean,
      required: false,
      default: true,
    },
    heading1: {
      type: Boolean,
      required: false,
      default: true,
    },
    heading2: {
      type: Boolean,
      required: false,
      default: true,
    },
    heading3: {
      type: Boolean,
      required: false,
      default: true,
    },
    image: {
      type: Boolean,
      required: false,
      default: true,
    },
    paragraph: {
      type: Boolean,
      required: false,
      default: true,
    },
    bold: {
      type: Boolean,
      required: false,
      default: true,
    },
    link: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
  emits: ["update:content"],
  setup(props, { emit }) {
    // Initialize the Tiptap editor
    const editor = useEditor({
      extensions: [StarterKit, Underline, Image, Link],
      content: props.content,
      onUpdate: ({ editor }) => {
        const html = editor.getHTML();
        emit("update:content", html);
      },
    });

    // Watch for changes in the content prop to update the editor
    watch(
      () => props.content,
      (newContent) => {
        if (editor.value && editor.value.getHTML() !== newContent) {
          editor.value.commands.setContent(newContent, false);
        }
      },
    );

    // Destroy the editor instance on unmount
    onBeforeUnmount(() => {
      if (editor.value) {
        editor.value.destroy();
      }
    });

    // Editor action methods
    const toggleBold = () => {
      if (editor.value) {
        editor.value.chain().focus().toggleBold().run();
      }
    };

    const toggleItalic = () => {
      if (editor.value) {
        editor.value.chain().focus().toggleItalic().run();
      }
    };

    const toggleUnderline = () => {
      if (editor.value) {
        editor.value.chain().focus().toggleUnderline().run();
      }
    };

    const toggleBulletList = () => {
      if (editor.value) {
        editor.value.chain().focus().toggleBulletList().run();
      }
    };

    const toggleOrderedList = () => {
      if (editor.value) {
        editor.value.chain().focus().toggleOrderedList().run();
      }
    };

    const toggleHeading = (level) => {
      if (editor.value) {
        editor.value.chain().focus().toggleHeading({ level }).run();
      }
    };

    // New methods
    const addImage = () => {
      if (editor.value) {
        const url = prompt("Enter image URL");
        if (url) {
          editor.value.chain().focus().setImage({ src: url }).run();
        }
      }
    };

    const addLink = () => {
      if (editor.value) {
        const url = prompt("Enter URL");
        if (url) {
          editor.value
            .chain()
            .focus()
            .extendMarkRange("link")
            .setLink({ href: url })
            .run();
        }
      }
    };

    const addParagraph = () => {
      if (editor.value) {
        editor.value.chain().focus().setParagraph().run();
      }
    };

    return {
      editor,
      toggleBold,
      toggleItalic,
      toggleUnderline,
      toggleBulletList,
      toggleOrderedList,
      toggleHeading,
      addImage,
      addLink,
      addParagraph,
    };
  },
};
</script>

<style scoped>
.toolbar {
  margin-bottom: 10px;
}

.editor {
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 10px;
  min-height: 200px;
  outline: none;
}
</style>
