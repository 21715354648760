<template>
  <v-card :loading="false" title="Front End Testing" class="text-left">
    <DynamicDialog
      :show="showDeactivateDialog"
      @dialogOk="runTest()"
      @dialogCancel="showDeactivateDialog = false"
      @update:showDyamicDialog="(state) => (showDeactivateDialog = state)"
      max-width="500"
      okBtnColor="success"
      okBtnLabel="Run Test"
      cancelBtnColor="none"
      cancelBtnVariant="text"
    >
      <template v-slot:title
        >Select Frontend Test for
        {{ this.enviroments[this.enviroment].label }}</template
      >
      <template v-slot:content>
        <p class="pb-4">
          <v-select
            v-model="selectedTest"
            :items="availableTests"
            label="Select Test"
            variant="outlined"
            density="compact"
            rounded="0"
          >
          </v-select>
        </p>
      </template>
      <template v-slot:okBtnTitle>Run Tests</template>
    </DynamicDialog>
    <v-table density="compact">
      <thead>
        <tr>
          <th v-for="(item, key) in enviroments" :key="key" class="text-center">
            {{ enviroments[key].label }}
          </th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td
            v-for="(item, key) in enviroments"
            :key="key"
            class="text-center pa-5"
          >
            <v-btn
              rounded="0"
              prepend-icon="mdi-cogs"
              color="success"
              :loading="enviroments[key].loading"
              :disabled="enviroments[key].disabled"
              @click="openTestDialog(key)"
              >Run Test</v-btn
            >
          </td>
        </tr>
        <tr>
          <td
            v-for="(item, key) in enviroments"
            :key="key"
            class="text-center pa-5"
          >
            <v-btn
              rounded="0"
              color="primary"
              variant="outlined"
              :loading="enviroments[key].loading"
              :disabled="enviroments[key].disabled"
              :href="enviroments[key].resultsURL"
              target="_blank"
              >Results</v-btn
            >
          </td>
        </tr>
      </tbody>
    </v-table>
  </v-card>
</template>

<script>
import DynamicDialog from "@/components/DynamicDialog.vue";

export default {
  name: "NAME",
  data: () => ({
    selectedTest: null,
    availableTests: null,
    showDeactivateDialog: false,
    enviroment: null,
    enviroments: {
      stg: {
        label: "Staging",
        loading: false,
        disabled: process.env.VUE_APP_ENVIRONMENT === "stg" ? false : true,
        resultsURL: "https://stg.app.localinsight.org/reports",
      },
      uat: {
        label: "UAT",
        loading: false,
        disabled: process.env.VUE_APP_ENVIRONMENT === "uat" ? false : true,
        resultsURL: "https://uat.app.localinsight.org/reports",
      },
      datalake: {
        label: "Datalake",
        loading: false,
        disabled: true,
        resultsURL: "",
      },
      prod: {
        label: "Production",
        loading: false,
        disabled: true,
        resultsURL: "https://app.localinsight.org/reports",
      },
      aus: {
        label: "Australia",
        loading: false,
        disabled: process.env.VUE_APP_ENVIRONMENT === "aus" ? false : true,
        resultsURL: "https://aus.localinsight.org/reports",
      },
    },
  }),
  components: {
    DynamicDialog,
  },
  computed: {},
  props: {
    model: null,
    item: {},
    relatedModelResults: {},
  },
  mounted() {
    this.getAvailableTests();
  },
  methods: {
    getAvailableTests() {
      this.$axios
        .get("/available-fe-tests")
        .then((response) => {
          // success from ajax call
          this.availableTests = response.data;
          this.availableTests.unshift("*** run all tests ***");
        })
        .catch((error) => {
          // handle error response
          this.emit.emit("systemMessage", {
            title: "There was an error getting available tests",
            message: error.response.data.message,
            timeout: 5000,
            colour: "warning",
          });
        });
    },
    openTestDialog(env) {
      this.showDeactivateDialog = true;
      this.enviroment = env;
    },
    runTest() {
      this.enviroments[this.enviroment].loading = true;
      this.enviroments[this.enviroment].disabled = true;
      this.showDeactivateDialog = false;
      this.$axios
        .post("/test-fe/" + this.selectedTest + "/" + this.enviroment)
        .then((response) => {
          // success from ajax call
          this.enviroments[this.enviroment].loading = false;
          this.enviroments[this.enviroment].disabled = false;
          console.log(response.data);
          // this.emit.emit("systemMessage", {
          //   title: "Test Result",
          //   message: response.data,
          //   timeout: null,
          //   colour: "prime",
          // });
        })
        .catch((error) => {
          // handle error response
          this.enviroments[this.enviroment].loading = false;
          this.enviroments[this.enviroment].disabled = false;
          this.emit.emit("systemMessage", {
            title: "There was an error running that test",
            message: error.response.data.message,
            timeout: 5000,
            colour: "warning",
          });
        });
    },
  },
  watch: {
    selectedItem: {
      handler() {
        // this.$emit('selectedItem', this.selectedItem)
      },
      deep: true,
    },
  },
};
</script>

<style scoped></style>
