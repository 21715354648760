<template>
  <div v-if="env !== 'prod'">
    <HeaderModule
      title="Dev Ops"
      icon="git"
      description="Tech Team Only!"
      width="1224px"
      padding="10px"
      :tabs="tabs"
    >
      <template #feTesting>
        <FETesting ref="feTesting" />
      </template>

      <template #deploy>
        <Deploy ref="deploy" />
      </template>
    </HeaderModule>
  </div>
</template>
<script>
import HeaderModule from "@/components/HeaderModule.vue";
import Deploy from "@/components/Deploy.vue";
import FETesting from "@/components/FETesting.vue";
import { systemMessages } from "@/mixins/SystemMessages";

export default {
  name: "DevOpsPage",
  mixins: [systemMessages],
  components: {
    HeaderModule,
    Deploy,
    FETesting,
  },
  props: {
    selectedTabRoute: {
      type: String,
      default: null,
    },
  },
  data: () => ({
    env: process.env.VUE_APP_ENVIRONMENT,
    tabs: [
      { title: "FE Testing", value: "feTesting", route: "fe-testing" },
      { title: "Deploy", value: "deploy", route: "deploy" },
    ],
  }),
  created() {
    // this.stuff();
  },
  methods: {},
};
</script>

<style scoped></style>
