<template>
  <div v-if="showCustomAreaLegend">
    <customAreaDetails
      :display="areaDetailsDialog"
      :area="selectedArea"
      @close="handleClose"
    />
    <div>
      <v-card flat class="pa-0">
        <v-expansion-panels>
          <v-expansion-panel>
            <v-expansion-panel-title
              style="height: 64px"
              class="dialog-title header-hover text-left"
              disable-icon-rotate
              @click="togglePanelCloseIcon()"
            >
              <div class="subheader" id="customAreaLegendTitle">
                Custom Areas
              </div>
              <template v-slot:actions>
                <div v-if="!panelToggle">
                  <v-icon color="success" size="32"
                    >mdi-information-outline</v-icon
                  >
                </div>
                <div v-else>
                  <v-icon color="error" size="32"
                    >mdi-arrow-up-drop-circle-outline</v-icon
                  >
                </div>
              </template>
            </v-expansion-panel-title>
            <v-divider></v-divider>
            <v-expansion-panel-text class="text-left px-0 mx-0 customAreaPanel">
              <v-card-actions
                v-if="areaDetails.length > 1"
                class="mapLegendSwitcher"
              >
                <v-switch
                  v-model="showOuterCustomAreas"
                  color="black"
                  :disabled="switchingOuterCustomAreas"
                  hide-details
                  class="outerCustomAreasSwitcher"
                  @update:modelValue="toggleShowOuterCustomAreas"
                >
                  <template v-slot:label>
                    <p>Combine area boundaries</p>
                    <p style="font-size: 14px; color: #4d4d4d">
                      Show only outer boundaries of custom areas
                    </p>
                  </template>
                </v-switch>
              </v-card-actions>
              <v-list id="customAreas" class="px-6 pt-2 pb-4">
                <v-list-item
                  v-for="area in areaDetails"
                  :key="area.id"
                  class="text-left px-0 mx-0"
                  id="customArea"
                >
                  <template v-slot:prepend>
                    <v-avatar>
                      <v-icon>mdi-vector-polygon</v-icon>
                    </v-avatar>
                  </template>
                  {{ area.name }}
                  <template v-slot:append>
                    <v-btn
                      variant="text"
                      icon="mdi-information"
                      aria-label="display area details"
                      title="show area details"
                      class="icon-success"
                      @click="displayAreaDetails(area)"
                    >
                    </v-btn>
                    <v-btn
                      variant="text"
                      icon="mdi-magnify-plus-outline"
                      aria-label="zoom to area"
                      title="zoom to area"
                      :loading="area.zooming"
                      @click="zoomToCustomArea(area)"
                      class="icon-success"
                      label="zoom to area"
                    >
                    </v-btn>
                    <v-btn
                      variant="text"
                      :icon="area.visible ? 'mdi-eye-outline' : 'mdi-eye-off'"
                      :loading="area.displayLoading"
                      @click="toggleAreaFromMap(area)"
                      :disabled="switchingOuterCustomAreas"
                      :class="area.visible ? 'icon-success' : 'icon-error'"
                      :title="area.visible ? 'hide area' : 'show area'"
                      label="toggle area from map"
                      aria-label="toggle area from map"
                    >
                    </v-btn>
                  </template>
                </v-list-item>
              </v-list>
            </v-expansion-panel-text>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-card>
      <v-divider></v-divider>
    </div>
  </div>
</template>

<script>
import customAreaDetails from "@/components/CustomAreaDetails";

export default {
  name: "CustomAreaLegend",
  computed: {
    showCustomAreaLegend() {
      if (this.customAreas.length > 0) {
        return true;
      } else {
        return false;
      }
    },
    headerColour() {
      return this.$store.state.config.siteConfig.toolbar_colour;
    },
    showOuterCustomAreas: {
      get() {
        return this.showOuterCustomAreasProp;
      },
      set(val) {
        this.$emit("update:showOuterCustomAreasProp", val);
      },
    },
  },
  data: () => ({
    areaDetails: [],
    areaDetailsDialog: false,
    selectedArea: null,
    panelToggle: false,
    loadingDetails: false,
    switchingOuterCustomAreas: false,
  }),
  components: {
    customAreaDetails,
  },
  props: {
    customAreas: {
      type: Array,
      required: true,
    },
    toggleAreaVisibility: {
      type: Function,
      required: true,
    },
    toggleOuterBoundareis: {
      type: Function,
      required: true,
    },
    zoomToArea: {
      type: Function,
      required: true,
    },
    showOuterCustomAreasProp: {
      type: Boolean,
      default: false,
      required: true,
    },
  },
  mounted() {},
  methods: {
    togglePanelCloseIcon() {
      this.panelToggle = !this.panelToggle;
    },
    async toggleAreaFromMap(area) {
      area.visible = !area.visible;
      area.displayLoading = true;

      const customAreasToShow = this.areaDetails
        .filter((a) => a.visible)
        .map((a) => a.id);

      await this.toggleAreaVisibility(customAreasToShow);

      area.displayLoading = false;
    },
    handleClose() {
      this.areaDetailsDialog = false;
    },
    displayAreaDetails(area) {
      this.selectedArea = area;
      this.areaDetailsDialog = true;
    },
    getAreaDetails() {
      this.loadingDetails = true;
      this.$axios
        .post("/get-custom-area-details", this.customAreas)
        .then(
          function (response) {
            this.loadingDetails = false;
            this.areaDetails = response.data;
          }.bind(this),
        )
        .catch(
          function (error) {
            this.loadingDetails = false;
            console.log(error);
          }.bind(this),
        );
    },
    async zoomToCustomArea(area) {
      area.zooming = true;
      await this.zoomToArea(area.id);
      area.zooming = false;
    },
    toggleShowOuterCustomAreas() {
      const customAreasToShow = this.areaDetails
        .filter((a) => a.visible)
        .map((a) => a.id);

      this.$nextTick(async () => {
        if (customAreasToShow?.length) {
          this.switchingOuterCustomAreas = true;
          await this.toggleOuterBoundareis(customAreasToShow);
          this.switchingOuterCustomAreas = false;
        }
      });
    },
  },
  watch: {
    customAreas: function () {
      this.getAreaDetails();
    },
  },
};
</script>

<style scoped>
.subheader {
  font-size: 18px;
  font-weight: 500;
  padding: 10px;
}

.legendSection {
  max-height: 250px;
  overflow-y: auto;
}

.icon-success:hover {
  color: green;
}

.icon-error:hover {
  color: red;
}

.v-expansion-panel-text :v-deep(.v-expansion-panel-text__wrapper) {
  padding-left: 0px;
  padding-right: 0px;
  margin-left: 0px;
  margin-right: 0px;
}

.v-list-item :v-deep(.v-list-item__content) {
  padding-left: 0px;
  padding-right: 0px;
  margin-left: 5px;
  margin-right: 5px;
}

.typeTitle {
  display: flex;
  align-items: center;
  flex-shrink: 0;
}

.typeTitleText {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.header-hover:hover {
  background-color: rgba(0, 0, 0, 0.1);
}
.mapLegendSwitcher {
  padding: 0 16px !important;
  background-color: #ebebeb;
}
</style>
<style>
/* force the description to be underneath the label */
.outerCustomAreasSwitcher .v-label {
  display: block !important;
}

.outerCustomAreasSwitcher .v-input__control {
  align-items: center;
}
.customAreaPanel .v-expansion-panel-text__wrapper {
  padding: 0 !important;
}
</style>
