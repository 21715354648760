<template>
  <div>
    <v-btn
      color="primary"
      variant="elevated"
      tile
      id="chooseYourDashboardButton"
      :disabled="disabled"
      @click="openRootDialog"
      aria-label="Data"
      class="px-4"
      prepend-icon="mdi-database-outline"
      >change data
    </v-btn>
    <v-dialog
      v-model="computedShowDialog"
      :fullscreen="dialogSize[0]"
      :max-width="dialogSize[1]"
      scrollable
    >
      <v-card max-height="90hv">
        <v-toolbar
          class="text-h5 text-center"
          :color="this.$store.state.config.siteConfig.toolbar_colour"
          max-height="70px"
          dark
          style="position: sticky; top: 0; z-index: 1000"
        >
          <v-btn
            v-if="!showChooseYourDashboard"
            @click="closeDialog()"
            class="mr-4"
            style="position: absolute"
            dark
            tabindex="2"
            icon
            aria-label="Close dialog panel"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-spacer>
            <v-toolbar-title v-if="showEditCustomDashboard"
              >Edit custom dashboard</v-toolbar-title
            >
            <v-toolbar-title v-else> Data</v-toolbar-title>
          </v-spacer>
        </v-toolbar>
        <v-card-text style="padding: 0">
          <div class="action-button-block">
            <v-breadcrumbs
              :items="breadcrumbs"
              divider=">"
              v-if="
                showBuildCustomDashboard && !showEditCustomDashboard && false
              "
            >
              <template v-slot:item="{ item }">
                <v-breadcrumbs-item href="#" @click.prevent="show(item)">
                  {{ item.text }}
                </v-breadcrumbs-item>
              </template>
            </v-breadcrumbs>
            <v-spacer></v-spacer>
          </div>
          <span v-if="showChooseYourDashboard">
            <v-card-text style="text-align: left" v-if="!isPublic">
              Choose between viewing the indicators for one of the standard
              themes or creating a custom dashboard with your choice of
              indicators</v-card-text
            >
            <v-card-text style="text-align: left" v-else>
              Choose one of the standard themes to display data for</v-card-text
            >
            <v-card-actions class="d-flex justify-center my-6">
              <v-btn
                @click="chooseOneTheme()"
                color="primary"
                variant="elevated"
                id="chooseThemeDashboardButton"
                tile
                class="mr-2"
                style="width: 45%"
                aria-label="Choose theme"
              >
                Choose theme
              </v-btn>
              <v-btn
                v-if="!isPublic"
                @click="buildCustomDashboard()"
                color="primary"
                variant="elevated"
                id="buildCustomDashboardButton"
                tile
                style="width: 45%"
                aria-label="Custom dashboard"
              >
                Custom dashboard
              </v-btn>
            </v-card-actions>
            <v-divider></v-divider>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                @click="closeDialog()"
                class="mr-2"
                color="error"
                variant="elevated"
                tile
                aria-label="Cancel"
              >
                Cancel
              </v-btn>
            </v-card-actions>
          </span>
          <span v-if="showChooseOneTheme">
            <v-row density="compact" class="pa-0 ma-0">
              <!-- lhc -->
              <v-col width="49%">
                <v-table fixed-header height="70vh" density="compact">
                  <template v-slot:default>
                    <thead>
                      <tr>
                        <th class="text-center">Themes</th>
                      </tr>
                    </thead>
                    <tbody id="themes">
                      <tr
                        v-for="(item, index) in data.themes"
                        :key="index"
                        tabindex="0"
                        @keydown.enter="singleThemeSelected = item.id"
                        :style="
                          item.id == hightlightRow
                            ? 'background-color:#ADACAC'
                            : 'background-color:;' + 'cursor:pointer;'
                        "
                      >
                        <td
                          @click="singleThemeSelected = item.id"
                          style="border-width: 0px !important"
                        >
                          {{ item.name }}
                        </td>
                      </tr>
                    </tbody>
                  </template>
                </v-table>
              </v-col>
              <v-divider vertical></v-divider>
              <!-- rhc -->
              <v-col width="49%">
                <v-table
                  id="chooseThemeTable"
                  fixed-header
                  height="70vh"
                  density="compact"
                  rounded="0"
                >
                  <template v-slot:default>
                    <thead>
                      <tr>
                        <th class="text-center">Theme Indicators</th>
                      </tr>
                    </thead>
                    <tbody>
                      <template v-if="!singleThemeSelectedIndicators.length">
                        <div class="text-center" style="padding: 20px">
                          <div v-if="!singleThemeSelected">
                            Choose a theme to view its indicators
                          </div>
                          <div v-else>
                            No data for
                            <b>{{
                              data.themes.find(
                                (item) => item.id == singleThemeSelected,
                              ).name
                            }}</b>
                          </div>
                        </div>
                      </template>
                      <template v-else>
                        <tr
                          v-for="(item, index) in singleThemeSelectedIndicators"
                          :key="index"
                        >
                          <td style="border-width: 0px !important">
                            {{ getNameOfIndicator(item.indicatorCode) }}
                          </td>
                        </tr>
                      </template>
                    </tbody>
                  </template>
                </v-table>
              </v-col>
            </v-row>
          </span>
          <div v-if="showBuildCustomDashboard">
            <div
              v-if="showEditCustomDashboard"
              class="user-dashboard-name-subsection"
            >
              <v-text-field
                class="ma-4 fields"
                variant="outlined"
                density="compact"
                rounded="0"
                hide-details="true"
                label="Custom Dashboard Name"
                placeholder="custom-dashboard"
                v-model="newNameOfCustomDashboard"
                autocomplete="off"
              ></v-text-field>
            </div>
            <div id="build-a-custom-dashboard" class="display-grid">
              <!--     First column       -->
              <div class="grid-container stickyBlock">
                <v-card-title class="text-center cell">
                  <v-spacer> Select a theme</v-spacer>
                </v-card-title>
                <v-radio-group v-model="customThemeSelected" class="ml-4">
                  <!--  Search   -->
                  <v-radio key="-3" value="-3" tabindex="-1">
                    <template v-slot:label>
                      <div tabindex="2" @keydown.enter="$event.target.click()">
                        Search ({{
                          customThemeIndicatorSearchTermIndicators.length
                        }})
                      </div>
                    </template>
                  </v-radio>
                  <!--  All indicators   -->
                  <v-radio key="-2" value="-2" tabindex="-1">
                    <template v-slot:label>
                      <div tabindex="2" @keydown.enter="$event.target.click()">
                        All indicators ({{ data.indicators.length }})
                      </div>
                    </template>
                  </v-radio>
                  <!--  Themes   -->
                  <v-radio
                    v-for="item in data.themes"
                    :key="item.id"
                    :value="item.id"
                    :id="`theme-${item.id}`"
                  >
                    <template v-slot:label>
                      <div tabindex="2" @keydown.enter="$event.target.click()">
                        {{
                          item.name +
                          " (" +
                          item.theme_indicators_matrices.filter(
                            (tim) =>
                              standardIndicatorsAsObject[tim.indicatorCode],
                          ).length +
                          ")"
                        }}
                      </div>
                    </template>
                  </v-radio>
                </v-radio-group>
              </div>
              <!--     Second column       -->
              <div class="grid-container columnWithScrollbar">
                <v-card-title class="cell text-center mb-5">
                  <v-spacer>
                    <span v-if="customThemeSelected == -2">
                      All indicators
                    </span>
                    <span v-else-if="customThemeSelected == -3">
                      Search results
                    </span>
                    <span v-else>
                      "{{
                        data.themes
                          .find(
                            (indicator) =>
                              indicator.id == this.customThemeSelected,
                          )
                          .name.trim()
                      }}" indicators
                    </span>
                  </v-spacer>
                </v-card-title>
                <div>
                  <v-text-field
                    v-if="customThemeSelected == -3"
                    class="mt-3"
                    clearable
                    variant="outlined"
                    density="compact"
                    tabindex="3"
                    rounded="0"
                    hide-details="true"
                    label="Search indicators"
                    prepend-inner-icon="mdi-magnify"
                    v-model="customThemeIndicatorSearchTerm"
                    @click:clear="indicatorSearchTermCleared"
                    autocomplete="off"
                  ></v-text-field>
                </div>
                <v-card-text
                  v-if="
                    customThemeSelected == -3 &&
                    customThemeIndicatorSearchTerm &&
                    customThemeSelectedIndicators.length == 0
                  "
                  class="text-center"
                  >No matching results
                </v-card-text>
                <div
                  v-for="(item, key) in customThemeSelectedIndicators"
                  class="checkbox-container"
                  :key="key"
                >
                  <input
                    v-model="item.selected"
                    :id="'checkbox' + key"
                    type="checkbox"
                    tabindex="3"
                    class="mr-2 mt-1 checkbox"
                    :key="key"
                    :value="item.indicatorCode"
                    @click="toggleIndicator(item)"
                  />
                  <label :for="'checkbox' + key" class="indicator-name ml-1">
                    {{ getNameOfIndicator(item.indicatorCode) }}
                  </label>
                </div>
              </div>
              <!--     Third column       -->
              <div class="grid-container columnWithScrollbar">
                <v-card-title class="cell mb-5 text-center">
                  Selected indicators
                </v-card-title>
                <div
                  v-for="(item, index) in selectedIndicators"
                  class="checkbox-container"
                  :key="index"
                >
                  <v-btn
                    :id="'remove_' + index"
                    class="mx-2 removeSelectedIndicator"
                    fab
                    dark
                    size="x-small"
                    color="primary"
                    title="remove selected indicator"
                    @click="toggleIndicator(item)"
                  >
                    <v-icon aria-label="delete" dark> mdi-delete </v-icon>
                  </v-btn>
                  <label :for="'remove_' + index" class="indicator-name ml-1">
                    {{ getNameOfIndicator(item.indicatorCode) }}
                  </label>
                </div>
              </div>
            </div>
          </div>
        </v-card-text>
        <v-card
          v-if="showBuildCustomDashboard && !showEditCustomDashboard"
          tile
        >
          <v-card-actions>
            <v-btn
              @click="closeDialog"
              class="ml-2"
              color="warning"
              variant="elevated"
              tile
              aria-label="Back"
            >
              Back
            </v-btn>
            <v-spacer></v-spacer>
            <div v-if="showBuildCustomDashboard && !showEditCustomDashboard">
              <v-btn
                id="viewTheme"
                :disabled="!this.selectedIndicators.length"
                @click="loadNewIndicators()"
                class="mr-2"
                width="100"
                color="success"
                variant="elevated"
                tile
                aria-label="View"
              >
                View
              </v-btn>
            </div>
          </v-card-actions>
        </v-card>
        <v-card v-if="showChooseOneTheme">
          <v-card-actions>
            <v-btn
              @click="
                showChooseOneTheme = false;
                showChooseYourDashboard = true;
              "
              class="ml-2"
              color="warning"
              variant="elevated"
              tile
              id="backToChooseOptionsButton"
              aria-label="back"
            >
              back
            </v-btn>
            <v-spacer></v-spacer>
            <v-btn
              id="viewTheme"
              :disabled="!this.selectedIndicators.length"
              @click="loadNewIndicators()"
              color="success"
              variant="elevated"
              width="100"
              tile
              class="mr-2"
              aria-label="View"
            >
              View
            </v-btn>
          </v-card-actions>
        </v-card>
        <v-card
          v-if="
            this.selectedIndicators.length > 0 &&
            showBuildCustomDashboard &&
            showEditCustomDashboard
          "
        >
          <v-card-actions>
            <v-btn
              @click="closeDialog()"
              class="ml-2"
              color="error"
              variant="elevated"
              tile
              aria-label="Cancel"
            >
              Cancel
            </v-btn>
            <v-spacer></v-spacer>
            <!--              :loading="computedSaveProcessOngoing"-->
            <v-btn
              @click="saveEditedCustomDashboardIndicators()"
              class="mr-3"
              color="success"
              variant="elevated"
              tile
              aria-label="Save"
            >
              Save
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  name: "DashboardDataSelector",
  data: () => ({
    breadcrumbs: [
      {
        text: "Choose your dashboard",
      },
    ],
    rootDialog: false,
    dashboardCaption: "",
    showChooseYourDashboard: true,
    showChooseOneTheme: false,
    showBuildCustomDashboard: false,
    showEditCustomDashboard: false,
    selectedIndicators: [], // the objects needs this structure min {id:n, indicatorCode:'groot'}
    singleThemeSelected: null,
    singleThemeSelectedIndicators: [],
    customThemeSelected: -3,
    customThemeSelectedIndicators: [],
    customThemeIndicatorSearchTerm: "",
    customThemeIndicatorSearchTermIndicators: [],
    newNameOfCustomDashboard: "",
    allSingleThemeSelectedIndicators: [],
    allCustomThemeIndicators: [],
  }),
  components: {},
  computed: {
    nameOfCustomDashboardBeingEdited: {
      get() {
        return this.computedCustomDashboardToEdit === 0
          ? ""
          : this.data.user_dashboards.find(
              (u_d) => u_d.id == this.computedCustomDashboardToEdit,
            ).name;
      },
    },
    isPublic() {
      return (
        this.$store.state.config.siteConfig.is_public_site &&
        this.$store.state.config.userProfile.is_public_site_user_profile
      );
    },
    dialogSize: {
      get() {
        if (this.showChooseYourDashboard) {
          return [false, 600];
        } else if (this.showChooseOneTheme) {
          return [false, 1000];
        } else {
          return [false, 1000];
        }
      },
    },
    hightlightRow: {
      get() {
        if (this.singleThemeSelected) {
          return this.singleThemeSelected;
        } else {
          return null;
        }
      },
    },
    showDoneButton: {
      get() {
        if (this.selectedIndicators.length > 0) {
          return true;
        } else {
          return false;
        }
      },
    },
    computedShowDialog: {
      get() {
        return this.showDialog;
      },
      set(val) {
        this.$emit("update:showDialog", val);
      },
    },
    computedSaveProcessOngoing: {
      get() {
        return this.saveProcessOngoing;
      },
      set(val) {
        this.$emit("update:saveProcessOngoing", val);
      },
    },
    computedCustomDashboardToEdit: {
      get() {
        return this.customDashboardToEdit;
      },
      set(val) {
        this.$emit("update:customDashboardToEdit", val);
      },
    },
  },
  props: {
    showDialog: {
      type: Boolean,
      required: true,
      default: false,
    },
    saveProcessOngoing: {
      type: Boolean,
      required: true,
      default: false,
    },
    data: {},
    standardIndicatorsAsObject: {},
    customDashboardToEdit: {
      type: Number,
      required: true,
      default: 0,
    },
    disabled: {
      type: Boolean,
      required: true,
      default: false,
    },
  },
  mounted() {},
  methods: {
    toggleIndicator(indicator) {
      indicator.selected = !indicator.selected;

      // update the selected indicators
      this.allCustomThemeIndicators.forEach((item) => {
        if (item.indicatorCode === indicator.indicatorCode) {
          item.selected = indicator.selected;
        }
      });

      // add or remove the indicator from the selected indicators
      if (indicator.selected) {
        this.selectedIndicators.push(indicator);
      } else {
        this.selectedIndicators = this.selectedIndicators.filter(
          (item) => item.indicatorCode !== indicator.indicatorCode,
        );
      }
    },
    closeDialog() {
      this.computedShowDialog = false;
      this.computedShowDialog = false;
      this.computedCustomDashboardToEdit = 0;
      this.resetDataComponent();
    },
    resetDataComponent() {
      // reset the checkboxes in case user will open the dialog again
      this.customThemeSelectedIndicators.forEach((item) => {
        delete item.selected;
      });

      this.singleThemeSelectedIndicators = [];
      this.singleThemeSelected = null;
      this.selectedIndicators = [];
      this.customThemeSelected = -3;
      this.customThemeIndicatorSearchTerm = "";
      this.customThemeSelectedIndicators = [];
      this.allCustomThemeIndicators = [];
    },
    getNameOfIndicator(indicatorCode) {
      if (
        typeof this.standardIndicatorsAsObject[indicatorCode] !== "undefined"
      ) {
        return this.standardIndicatorsAsObject[indicatorCode].indicator_name;
      } else {
        return "";
      }
    },
    loadNewIndicators() {
      // re-package the selectedIndicators so the dashboard can handle it
      var selectedIndicatorsStringArray = [];

      // if it's custom dashboard
      if (typeof this.selectedIndicators[0] === "object") {
        this.selectedIndicators.forEach((indicator) => {
          selectedIndicatorsStringArray.push(indicator.indicatorCode);
        });
      } else {
        // if it's choose theme
        this.selectedIndicators.forEach((indicator) => {
          selectedIndicatorsStringArray.push(indicator);
        });
      }

      this.$emit("load-new-indicators", {
        data: selectedIndicatorsStringArray,
        flag: this.showBuildCustomDashboard,
        caption: this.dashboardCaption,
        theme: this.showChooseOneTheme,
      });
      this.closeDialog();
    },
    saveEditedCustomDashboardIndicators() {
      // this.computedSaveProcessOngoing = true;
      this.$emit("save-custom-dashboard", {
        data: this.selectedIndicators,
        id: this.computedCustomDashboardToEdit,
        flag: false,
        name: this.newNameOfCustomDashboard,
        caption: 'The "' + this.newNameOfCustomDashboard + '" theme',
      });
    },
    show(item) {
      switch (item.text) {
        case "Choose your dashboard":
          this.chooseYourDashboard();
          break;
        case "Choose one theme":
          this.chooseOneTheme();
          break;
        case "Build custom dashboard":
          this.buildCustomDashboard();
          break;
        case "Edit custom dashboard":
          this.editCustomDashboard();
          break;
        default:
          break;
      }
    },
    openRootDialog() {
      // show the choose one theme panel
      this.computedShowDialog = true;
      this.showChooseYourDashboard = true;
      this.showChooseOneTheme = false;
      this.showBuildCustomDashboard = false;
      this.showEditCustomDashboard = false;
      this.breadcrumbs = [{ text: "Choose your dashboard" }];
      this.dashboardCaption = "Custom Dashboard";
      this.resetDataComponent();
    },
    chooseOneTheme() {
      // show the choose one theme panel
      this.showChooseYourDashboard = false;
      this.showChooseOneTheme = true;
      this.showBuildCustomDashboard = false;
      this.showEditCustomDashboard = false;
      this.breadcrumbs = [
        { text: "Choose your dashboard" },
        { text: "Choose one theme" },
      ];
      this.resetDataComponent();
    },
    buildCustomDashboard() {
      // show the build custom dashboard panel
      this.showChooseYourDashboard = false;
      this.showChooseOneTheme = false;
      this.showBuildCustomDashboard = true;
      this.showEditCustomDashboard = false;
      this.breadcrumbs = [
        { text: "Choose your dashboard" },
        { text: "Build custom dashboard" },
      ];
      this.resetDataComponent();

      // get the list of all indicators
      this.allCustomThemeIndicators = this.data.indicators.map((element) => ({
        indicatorCode: element.indicator_code,
        selected: false,
      }));
    },
    editCustomDashboard() {
      // show the edit custom dashboard panel
      this.newNameOfCustomDashboard = this.nameOfCustomDashboardBeingEdited;
      this.showChooseYourDashboard = false;
      this.showChooseOneTheme = false;
      this.showBuildCustomDashboard = true;
      this.showEditCustomDashboard = true;
      this.breadcrumbs = [
        { text: "Choose your dashboard" },
        { text: "Edit a custom dashboard" },
      ];
      this.resetDataComponent();
    },
    searchForIndicators(arr, query) {
      const search = arr
        .filter((el) =>
          el.indicator_name.toLowerCase().includes(query.toLowerCase()),
        )
        .map((element) => ({ indicatorCode: element.indicator_code }));

      search.forEach((item) => {
        const relatedIndicator = this.allCustomThemeIndicators.find(
          (indicator) => indicator.indicatorCode === item.indicatorCode,
        );
        if (relatedIndicator) {
          item.selected = relatedIndicator.selected;
        }
      });

      return search;
    },
    indicatorSearchTermCleared() {
      this.customThemeIndicatorSearchTerm = "";
    },
  },
  watch: {
    computedShowDialog: {
      handler() {
        // if we are showing the dialog
        if (this.computedShowDialog) {
          // check if it is being opened to edit a custom dashboard
          if (this.customDashboardToEdit) {
            // and if it is go straight to that panel
            this.editCustomDashboard();
          }
        }
      },
      deep: true,
    },
    singleThemeSelected: {
      handler() {
        if (this.singleThemeSelected) {
          this.singleThemeSelectedIndicators = this.data.themes
            .find((indicator) => indicator.id == this.singleThemeSelected)
            .theme_indicators_matrices.filter(
              (tim) => this.standardIndicatorsAsObject[tim.indicatorCode],
            );
          this.selectedIndicators = this.singleThemeSelectedIndicators.map(
            (element) => element.indicatorCode,
          );

          this.dashboardCaption =
            'The "' +
            this.data.themes.find(
              (indicator) => indicator.id == this.singleThemeSelected,
            ).name +
            '" theme';
        }
      },
      deep: true,
    },
    customThemeIndicatorSearchTerm: {
      handler() {
        if (this.customThemeIndicatorSearchTerm) {
          this.customThemeSelected = -3;
          this.customThemeIndicatorSearchTermIndicators =
            this.searchForIndicators(
              this.data.indicators,
              this.customThemeIndicatorSearchTerm,
            );
          this.customThemeSelectedIndicators =
            this.customThemeIndicatorSearchTermIndicators;
        } else {
          this.customThemeIndicatorSearchTermIndicators = [];
          this.customThemeSelectedIndicators =
            this.customThemeIndicatorSearchTermIndicators;
        }
      },
      deep: true,
    },
    customThemeSelected: {
      handler() {
        if (this.customThemeSelected == -2) {
          // all indicators
          this.customThemeSelectedIndicators = this.allCustomThemeIndicators;
        } else if (this.customThemeSelected == -3) {
          // search results
          this.customThemeSelectedIndicators =
            this.customThemeIndicatorSearchTermIndicators;
        } else {
          // one of the normal themes
          const selectedTheme = this.data.themes.find(
            (indicator) => indicator.id == this.customThemeSelected,
          );
          this.customThemeSelectedIndicators =
            selectedTheme.theme_indicators_matrices.filter(
              (tim) => this.standardIndicatorsAsObject[tim.indicatorCode],
            );

          // update the checkboxes
          this.customThemeSelectedIndicators.forEach((item) => {
            const relatedIndicator = this.allCustomThemeIndicators.find(
              (indicator) => indicator.indicatorCode === item.indicatorCode,
            );
            if (relatedIndicator) {
              item.selected = relatedIndicator.selected;
            }
          });
        }
      },
      deep: true,
    },
    computedCustomDashboardToEdit: {
      handler() {
        if (this.computedCustomDashboardToEdit > 0) {
          this.selectedIndicators = this.data.user_dashboards
            .find((u_d) => u_d.id === this.computedCustomDashboardToEdit)
            .user_dashboard_theme_indicator_matrices.map((element) => ({
              indicatorCode: element.indicatorCode,
              selected: true,
            }));

          // if there are no indicators, get them
          !this.allCustomThemeIndicators.length
            ? (this.allCustomThemeIndicators = this.data.indicators.map(
                (element) => ({
                  indicatorCode: element.indicator_code,
                  selected: false,
                }),
              ))
            : null;

          // update the checkboxes
          this.allCustomThemeIndicators.forEach((item) => {
            this.selectedIndicators.forEach((indicator) => {
              if (item.indicatorCode === indicator.indicatorCode) {
                item.selected = indicator.selected;
              }
            });
          });
        }
      },
      deep: true,
    },
  },
};
</script>

<style scoped>
.v-table--density-compact {
  --v-table-header-height: 28px;
  --v-table-row-height: 28px;
}
.areaName {
  cursor: pointer;
}
.display-grid {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-column-gap: 10px;
  height: 70vh;
}

.grid-container {
  border-right: 1px solid #ccc;
  padding: 0 10px;
}

.stickyBlock {
  position: sticky;
  top: 0;
  max-height: 75vh;
  overflow-y: auto;
  /* hide the scrollbar */
  &::-webkit-scrollbar {
    width: 0px;
    background: transparent;
  }
}

.columnWithScrollbar {
  position: sticky;
  top: 0;
  max-height: 75vh;
  overflow-y: auto;
}

/* make the scrollbar smaller for the column with indicators*/
.columnWithScrollbar::-webkit-scrollbar {
  width: 5px;
  background: #f1f1f1;
}

/* change the scrollbar colour for the column with indicators */
.columnWithScrollbar::-webkit-scrollbar-thumb {
  background-color: rgb(195, 195, 195);
}

.delete-icon {
  font-size: 1.4rem;
  display: inline-block;
}

.delete-icon:hover {
  transform: scale(1.1);
  color: #e74c3c;
  transition:
    color 0.2s,
    transform 0.2s;
}

.cell {
  border-bottom: 2px solid #ccc;
  padding: 20px;
  word-break: break-word;
  justify-content: center;
}

.disabled {
  pointer-events: none;
  opacity: 0.5;
}

.checkbox-container {
  display: flex;
  align-items: flex-start;
  padding: 5px 10px;
}

.checkbox-container:hover {
  .delete-icon {
    transform: scale(1.1);
    color: #e74c3c;
    transition:
      color 0.2s,
      transform 0.2s;
  }
}

.checkbox {
  flex-shrink: 0;
  width: 17px;
  height: 17px;
  cursor: pointer;
}

.indicator-name {
  font-size: 16px;
  white-space: pre-wrap;
  cursor: pointer;
}

#choose-one-theme {
  grid-template-columns: 1fr 1fr;
}

#chooseYourDashboardButton {
  padding: 0px 8px;
  margin: 5px;
}

#build-a-custom-dashboard {
  grid-template-columns: 1fr 1fr 1fr;
}

#choose-your-dashboard {
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr 1fr;
  grid-template-areas:
    "title title"
    "text text"
    ". .";
}

.action-button-block {
  display: flex;
  flex-wrap: wrap;
  flex: 0 1 auto;
  align-items: center;
}

.user-dashboard-name-subsection {
  display: grid;
  min-width: 300pt;
  max-width: 33%;
}

#save-edit-dashboard-button {
  position: absolute;
  right: 0;
  top: 0;
}

.title {
  grid-area: title;
}

.text {
  grid-area: text;
}

.d-flex {
  display: flex;
}

.justify-center {
  justify-content: center;
}

.mr-2 {
  margin-right: 0.5rem; /* Adjust the margin as needed */
}

#chooseThemeDashboardButton :deep(.v-btn__content),
#buildCustomDashboardButton :deep(.v-btn__content) {
  white-space: normal !important;
}

#chooseThemeDashboardButton.v-btn,
#buildCustomDashboardButton.v-btn {
  min-height: 2.5em;
}
</style>
