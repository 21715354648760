<template>
  <v-container class="pa-0 ma-0">
    <!-- Authorisation -->
    <v-card class="my-4 main">
      <!-- Header -->
      <v-card-title class="cardTitle border-b-thin border-b-md pa-0 pr-4">
        <v-card-actions>
          <v-icon
            :color="isAuthorisationValid ? 'green' : '#FB8C00'"
            class="ma-2"
          >
            {{ isAuthorisationValid ? "mdi-check-circle" : "mdi-alert" }}
          </v-icon>
          <div>Authorisation</div>
        </v-card-actions>
      </v-card-title>

      <!-- Content under the panel -->
      <v-card-text>
        <div class="text caption mt-3">
          An authorisation token is needed to access the Datastore API, ensuring
          your requests remain secure and verified. Please select an existing
          token if you have one, or create a new token to continue.
        </div>
        <!-- Dropdown -->
        <v-select
          v-model="selectedKey"
          :items="apiKeys"
          item-title="nickname"
          item-value="id"
          label="Select Authorisation Token"
          class="mt-4"
          variant="outlined"
          density="compact"
          rounded="0"
        ></v-select>
        <!-- Button to create new API key -->
        <div class="button-container">
          <v-btn
            @click="$router.push('/datastore/api-keys')"
            variant="elevated"
            prepend-icon="mdi-plus"
            color="#0e5b99"
            rounded="0"
            class="mt-4 create-api-key-btn"
          >
            Create API Key
          </v-btn>
        </div>
      </v-card-text>
    </v-card>

    <!-- Indicator -->
    <v-card class="my-4 main">
      <!-- Header -->
      <v-card-title class="cardTitle border-b-thin border-b-md pa-0 pr-4">
        <v-card-actions>
          <v-icon
            :color="isIndicatorComplete ? 'green' : '#FB8C00'"
            class="ma-2"
          >
            {{ isIndicatorComplete ? "mdi-check-circle" : "mdi-alert" }}
          </v-icon>
          <div>Indicator</div>
        </v-card-actions>
      </v-card-title>

      <!-- No indicator selected -->
      <v-card-text v-if="!selectedIndicator" class="text-left pa-4">
        <!-- Alert -->
        <v-alert color="info" icon="mdi-information">
          <div style="font-weight: 500">Add an indicator</div>
          <div>Add an indicator to build you query</div>
        </v-alert>
        <div class="button-container">
          <v-btn
            class="mt-4 create-api-key-btn"
            variant="elevated"
            prepend-icon="mdi-plus"
            color="#0e5b99"
            @click="$router.push('/datastore/catalogue')"
            rounded="0"
          >
            add indicator from catalogue
          </v-btn>
        </div>
      </v-card-text>

      <!-- Selected indicator -->
      <v-card-text v-else class="text-left pa-4">
        <v-card-actions class="pa-0">
          <div>
            <div class="text-subtitle-1" style="font-weight: 400">
              {{ selectedIndicator.indicator_name }}
            </div>
            <div class="text-caption" style="color: gray">
              <v-icon icon="mdi-code-tags" size="15px" />
              {{ selectedIndicator.indicator_code }}
            </div>
          </div>
          <v-spacer />
          <div>
            <v-icon
              @click="removeIndicatorDialog = true"
              icon="mdi-delete"
              color="error"
            />
          </div>
        </v-card-actions>

        <!-- Indicator timepoints -->
        <v-card-actions class="px-0">
          <v-select
            v-model="queryParameters.timepoint"
            label="Time point"
            :items="timepoints"
            variant="outlined"
            :no-data-text="
              loadingTimepoints
                ? 'Loading timepoints'
                : 'No timepoints available'
            "
            :loading="loadingTimepoints"
            class="py-4"
            item-value="dateSelection"
            item-title="label"
            hide-details
            density="compact"
            rounded="0"
          />
        </v-card-actions>

        <!-- Checkboxes -->
        <div class="text-subtitle-1" style="font-weight: 400">Values</div>
        <v-card-text class="pa-0">
          <div
            @click.stop="
              queryParameters.raw_values = !queryParameters.raw_values
            "
            class="d-flex pa-1 checkbox-container"
          >
            <v-checkbox
              v-model="queryParameters.raw_values"
              label="Raw values"
              hide-details
              color="primary"
            />
          </div>
          <div
            @click.stop="queryParameters.rates = !queryParameters.rates"
            class="d-flex pa-1 checkbox-container"
          >
            <v-checkbox
              v-model="queryParameters.rates"
              label="Rates"
              hide-details
              color="primary"
            />
          </div>
        </v-card-text>
      </v-card-text>
      <DateRangePicker
        v-model:dialogProp="pickDateRange"
        @setRange="setCustomTimeRange"
        validate-date-range
      />

      <DynamicDialog
        :show="removeIndicatorDialog"
        @dialogOk="removeIndicator()"
        @dialogCancel="removeIndicatorDialog = false"
        @update:showDyamicDialog="(state) => (removeIndicatorDialog = state)"
        max-width="450"
        okBtnColor="error"
        cancelBtnColor="none"
        cancelBtnVariant="text"
      >
        <template v-slot:title>Remove indicator</template>
        <template v-slot:content>
          Are you sure you want to remove this indicator from the query?
        </template>
        <template v-slot:okBtnTitle>remove indicator</template>
      </DynamicDialog>
    </v-card>

    <!-- Areas -->
    <DatastoreQueryBuilderAreas v-model:selectedAreas="selectedCustomAreas" />

    <!-- Data Format and Options -->
    <v-card class="my-4 main">
      <!-- Header -->
      <v-card-title class="cardTitle border-b-thin border-b-md pa-0 pr-4">
        <v-card-actions>
          <v-icon
            :color="isDataFormatOptionsValid ? 'green' : '#FB8C00'"
            class="ma-2"
          >
            {{ isDataFormatOptionsValid ? "mdi-check-circle" : "mdi-alert" }}
          </v-icon>
          <div>Data Format & Options</div>
        </v-card-actions>
      </v-card-title>

      <!-- Content under the panel -->
      <v-card-text class="mt-2 py-2">
        <!-- Dropdown -->
        <v-select
          v-model="queryParameters.response_type"
          :items="[
            { title: 'CSV', value: 'csv' },
            { title: 'JSON', value: 'json' },
          ]"
          label="Response type"
          variant="outlined"
          density="compact"
          rounded="0"
          hide-details="true"
        ></v-select>
      </v-card-text>
      <v-card-text class="py-2">
        <v-select
          v-model="queryParameters.data_format"
          :items="[
            { title: 'Stacked/long format', value: 'stacked' },
            { title: 'Wide format', value: 'wide' },
          ]"
          label="Data formatting"
          variant="outlined"
          density="compact"
          rounded="0"
          hide-details="true"
        ></v-select>
      </v-card-text>
      <v-card-text class="py-2">
        <v-select
          v-model="queryParameters.area_code"
          :items="[
            { title: 'Include area code', value: 'include' },
            { title: 'Don\'t include area code', value: 'exclude' },
          ]"
          label="Area code"
          variant="outlined"
          density="compact"
          rounded="0"
          hide-details="true"
        ></v-select>
      </v-card-text>
      <v-card-text class="mb-2 py-2">
        <v-select
          v-model="queryParameters.unit_labels"
          :items="[
            { title: 'Include unit labels', value: 'include' },
            { title: 'Don\'t include unit labels', value: 'exclude' },
          ]"
          label="Unit labels"
          variant="outlined"
          density="compact"
          rounded="0"
          hide-details="true"
        ></v-select
      ></v-card-text>
    </v-card>

    <!-- Query -->
    <v-card class="my-4 main">
      <!-- Header -->
      <v-card-title class="cardTitle border-b-thin border-b-md pa-0 pr-4">
        <v-card-actions>
          <v-icon :color="isQueryComplete ? 'green' : '#FB8C00'" class="ma-2">
            {{ isQueryComplete ? "mdi-check-circle" : "mdi-alert" }}
          </v-icon>
          <div class="font-weight-medium">Your Query</div>
        </v-card-actions>
      </v-card-title>

      <v-card-text class="text-left pa-4">
        <!-- Alert -->
        <v-alert v-if="!isQueryComplete" color="info" icon="mdi-information">
          <div style="font-weight: 500">Complete your query</div>
          Your query is missing some details. Complete the fields above to
          finish your query.
        </v-alert>

        <div v-else icon="mdi-check-circle" class="text caption">
          Your query is ready! Copy the HTTP API query below and use it in your
          preferred tool to access the data. Happy querying!

          <!-- Section for copying the url -->
          <v-card
            class="mt-4 grey-background"
            variant="outlined"
            density="compact"
            style="border: 1px solid rgba(0, 0, 0, 0.12)"
          >
            <v-card-text
              ><v-row>
                <v-col class="text-left"> HTTP </v-col>
                <v-col class="text-right" cols="auto">
                  <div
                    @click.stop="copyUrl()"
                    style="cursor: pointer; width: fit-content"
                  >
                    <v-icon
                      icon="mdi-content-copy"
                      class="mr-0 mb-1"
                      size="small"
                    />
                    COPY
                  </div>
                </v-col>
              </v-row>
            </v-card-text>
            <v-card class="url" rounded="0">
              <v-card-text>{{ url }}</v-card-text>
            </v-card>
          </v-card>
        </div>
      </v-card-text>
    </v-card>

    <v-card class="my-4 main">
      <v-btn
        width="100%"
        height="61"
        prepend-icon="mdi-refresh"
        color="error"
        @click="resetFormDialog = true"
        variant="text"
      >
        reset form
      </v-btn>

      <DynamicDialog
        :show="resetFormDialog"
        @dialogOk="resetForm()"
        @dialogCancel="resetFormDialog = false"
        @update:showDyamicDialog="(state) => (resetFormDialog = state)"
        max-width="450"
        okBtnColor="error"
        cancelBtnColor="none"
        cancelBtnVariant="text"
      >
        <template v-slot:title>Confirm reset</template>
        <template v-slot:content>
          Are you sure you want to reset the form? This will clear all the
          information you’ve entered so far.?
        </template>
        <template v-slot:okBtnTitle>reset form</template>
      </DynamicDialog>
    </v-card>
  </v-container>
</template>

<script>
import { systemMessages } from "@/mixins/SystemMessages";
import DynamicDialog from "@/components/DynamicDialog.vue";
import DatastoreQueryBuilderAreas from "@/components/DatastoreQueryBuilderAreas.vue";
import DateRangePicker from "@/components/DateRangePicker.vue";
import { copyToClipboard } from "@/mixins/CopyToClipboard";

export default {
  mounted() {},
  name: "DatastoreQueryBuilder",
  mixins: [systemMessages],
  components: {
    DynamicDialog,
    DatastoreQueryBuilderAreas,
    DateRangePicker,
  },
  data: () => ({
    queryParameters: {
      indicator: null,
      area: null,
      raw_values: false,
      rates: false,
      timepoint: null,
      response_type: null,
      data_format: null,
      area_code: null,
      unit_labels: null,
    },
    selectedKey: null,
    timepoints: [],
    removeIndicatorDialog: false,
    loadingTimepoints: false,
    pickDateRange: false,
    resetFormDialog: false,
    selectedCustomAreas: [],

    // timepoint range
    show_start_year: true,
    show_end_year: true,
    start_year: new Date().getFullYear(),
    start_month: 0,
    end_year: new Date().getFullYear(),
    end_month: 0,
    months: {
      0: "January",
      1: "February",
      2: "March",
      3: "April",
      4: "May",
      5: "June",
      6: "July",
      7: "August",
      8: "September",
      9: "October",
      10: "November",
      11: "December",
    },
  }),
  computed: {
    url() {
      let params = [];

      for (const key in this.queryParameters) {
        const value = this.queryParameters[key];
        if (value !== null && value !== false) {
          params.push(`${key}=${value}`);
        }
      }
      if (this.selectedKey) {
        params.push(`key=${this.selectedKey}`);
      }
      if (this.selectedIndicator?.id) {
        params.push(`indicator_id=${this.selectedIndicator.id}`);
      }
      if (this.selectedCustomAreas?.length) {
        params.push(
          `area_ids=${this.selectedCustomAreas.map((area) => area.id).join(",")}`,
        );
      }

      return `api.localinsight.org.com/api/datastore/?${params.join("&")}`;
    },
    isAuthorisationValid() {
      return this.selectedKey;
    },
    isDataFormatOptionsValid() {
      return (
        this.queryParameters.response_type &&
        this.queryParameters.data_format &&
        this.queryParameters.area_code &&
        this.queryParameters.unit_labels
      );
    },
    isIndicatorComplete() {
      return (
        this.selectedIndicator?.id &&
        (this.queryParameters?.raw_values || this.queryParameters?.rates) &&
        this.queryParameters?.timepoint
      );
    },
    isQueryComplete() {
      return (
        this.isAuthorisationValid &&
        this.isDataFormatOptionsValid &&
        this.isIndicatorComplete
      );
    },
    selectedIndicator: {
      get() {
        return this.$store.getters["datastore/selectedIndicator"];
      },
      set(val) {
        this.$store.commit("datastore/setSelectedIndicator", val);
      },
    },
    apiKeys: {
      get() {
        return this.$store.getters["datastore/activeApiKeys"];
      },
    },
  },
  methods: {
    copyUrl() {
      copyToClipboard(this.url);
      this.successPill({
        icon: "mdi-content-copy",
        title: "Copied to clipboard",
        showClose: false,
      });
    },
    async getIndicatorTimepoints() {
      if (this.loadingTimepoints) return;

      this.loadingTimepoints = true;
      try {
        const response = await this.$axios.get(
          `/datastore/query-builder/get-indicator-timepoints/${this.selectedIndicator?.id}`,
        );

        this.timepoints = response.data.timepoints;
      } catch (error) {
        console.error(error);
      } finally {
        this.loadingTimepoints = false;
      }
    },
    removeIndicator() {
      this.selectedIndicator = null;
      this.removeIndicatorDialog = false;
      this.timepoints = [];
    },
    setCustomTimeRange(range) {
      // push to the list if it doesn't exist
      if (
        !this.timepoints.some((timepoint) => timepoint.dateSelection === range)
      ) {
        this.timepoints.push(range);
      }

      // set the selected timepoint
      this.queryParameters.timepoint = range;

      this.pickDateRange = false;
    },
    resetForm() {
      this.selectedIndicator = null;
      this.timepoints = [];
      this.selectedCustomAreas = [];
      this.selectedKey = null;
      this.queryParameters = {
        indicator: null,
        area: null,
        raw_values: false,
        rates: false,
        timepoint: null,
        response_type: null,
        data_format: null,
        area_code: null,
        unit_labels: null,
      };

      this.resetFormDialog = false;
    },
  },
  watch: {
    selectedIndicator: {
      handler(newVal, oldVal) {
        // hit on every indicator change
        if (newVal && newVal?.id !== oldVal?.id) {
          this.getIndicatorTimepoints();

          this.queryParameters.indicator = newVal.id;
        }
      },
      deep: true,
    },
    "queryParameters.timepoint": function (newVal, oldVal) {
      if (newVal && newVal !== oldVal) {
        if (newVal === "date_range") {
          // open date picker
          this.pickDateRange = !this.pickDateRange;

          // reset these
          this.queryParameters.timepoint = null;
        }
      }
    },
  },
};
</script>

<style scoped>
.circle {
  width: 11px;
  height: 11px;
  border-radius: 10px;
}

.button-container {
  display: flex;
  justify-content: flex-end;
}
.caption {
  text-align: left;
  color: #4d4d4d;
}
.main {
  width: calc(100% - 32px);
  max-width: 800px;
  margin: 0 auto;
  border: solid 1px #c2c2c2;
  border-radius: 8px;
}
@media (min-width: 600px) {
  .main {
    width: auto;
  }
}
.select-key {
  color: #4d4d4d;
}

.checkbox-container {
  border-left: solid 1px gray;
  border-right: solid 1px gray;
  cursor: pointer;
}
.checkbox-container:nth-of-type(1) {
  border-bottom: solid gray 1px;
  border-top: solid gray 1px;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}
.checkbox-container:nth-of-type(2) {
  border-bottom: solid gray 1px;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
}
.grey-background {
  background-color: #e0e0e0; /* Light grey background */
}
.url {
  font-family: "Courier New", Courier, monospace;
  background-color: #f9f9f9;
  font-weight: 600;
}
</style>
