<template>
  <v-theme-provider theme="localInsightLightTheme">
    <v-container>
      <!-- Monthly Data Usage Section -->
      <v-row class="mb-4">
        <v-col class="pl-0 pr-0">
          <v-card class="usage-card py-2" style="word-break: break-word">
            <v-card-title>Monthly data usage limit</v-card-title>
            <v-card-text>
              The progress bar below displays the total data transferred out by
              all users in this organisation. Please note that any unused
              allowance will not roll over into the next calendar month.
            </v-card-text>
            <div class="d-flex">
              <v-progress-linear
                :color="usage_bar_colour"
                bg-color="rgba(0, 0, 0, 0.12)"
                height="8"
                :model-value="month_usage_percent"
                rounded="lg"
                rounded-bar
                class="mt-4 mb-1 mx-4"
              ></v-progress-linear>
            </div>
            <div class="text-right mx-4 mb-4 text-caption">
              {{ month_transferred_gb }}GB/{{ month_limit_gb }}GB ({{
                month_usage_percent
              }}% used)
            </div>
          </v-card>
        </v-col>
      </v-row>

      <!-- My Usage Section -->
      <v-row align="end" class="mt-2">
        <v-col align="left" class="pl-0 py-0">
          <h2 class="text-h6 text">My Usage</h2>
        </v-col>
        <v-col align="right" class="pr-0 py-0">
          <v-select
            density="compact"
            variant="outlined"
            rounded="0"
            max-width="215px"
            hide-details="true"
            :items="usageFilterArray"
            v-model="selectedUsageFilter"
            :change="fetchApiStats()"
            class="bg-white"
          ></v-select>
        </v-col>
      </v-row>
      <v-row>
        <v-col class="pl-0">
          <v-card
            class="kpi-card"
            style="border-bottom: 4px solid rgba(14, 91, 153, 1)"
          >
            <v-card-title class="title">{{ kpis.usage_requests }}</v-card-title>
            <v-card-subtitle class="subtitle">
              <v-icon icon="mdi-room-service-outline"></v-icon>
              Requests
            </v-card-subtitle>
          </v-card>
        </v-col>

        <v-col>
          <v-card
            class="kpi-card"
            style="border-bottom: 4px solid rgba(176, 0, 32, 1)"
          >
            <v-card-title class="title">{{ kpis.usage_errors }}</v-card-title>
            <v-card-subtitle class="subtitle">
              <v-icon icon="mdi-alert"></v-icon>
              Errors
            </v-card-subtitle>
          </v-card>
        </v-col>

        <v-col class="pr-0">
          <v-card
            class="kpi-card"
            style="border-bottom: 4px solid rgba(38, 89, 40, 1)"
          >
            <v-card-title class="title"
              >{{ round2dp(kpis.usage_transferred_mb / 1024) }} GB</v-card-title
            >
            <v-card-subtitle class="subtitle">
              <v-icon icon="mdi-download"></v-icon>
              Data transferred out
            </v-card-subtitle>
          </v-card>
        </v-col>
      </v-row>

      <v-row>
        <v-divider class="my-3"></v-divider>
      </v-row>

      <!-- API Keys Section -->
      <v-row>
        <v-col align-self="end" align="left" class="pl-0 py-0 mb-3">
          <h2 class="text-h6 text">API Keys</h2>
        </v-col>
        <v-col align="right" class="pr-0 py-0">
          <v-btn
            variant="flat"
            rounded="0"
            color="primary"
            align="right"
            class="my-3"
            @click="newKeyDialog()"
          >
            <v-icon icon="mdi-plus" size="16" class="pr-3"></v-icon>
            New API Key
          </v-btn>
        </v-col>
      </v-row>
      <v-row>
        <v-data-table
          align="left"
          class="elevation-1 rounded"
          v-model:sort-by="tableSort"
          :headers="apiTableHeaders"
          :items="apiKeys"
          :items-per-page="keysPerPage"
          :hide-default-footer="apiKeys.length <= keysPerPage"
        >
          <!-- API Key column override -->
          <template v-slot:[`item.key`]="{ item }">
            <div class="d-flex">
              <div class="me-auto">
                {{ obfuscateString(item.key) }}
              </div>
              <v-btn
                variant="plain"
                size="small"
                :slim="true"
                @click="valueToClipboard(item.key)"
                class="pa-0"
              >
                <v-icon icon="mdi-content-copy"></v-icon>
              </v-btn>
            </div>
          </template>
          <!-- status column override -->
          <template v-slot:[`item.status`]="{ item }">
            <v-chip
              :class="['text-capitalize', getStatusChipColour(item.status)]"
            >
              {{ item.status }}
            </v-chip>
          </template>
          <!-- action column -->
          <template v-slot:[`item.action`]="{ item }">
            <v-btn
              :id="'api-row-' + item.id"
              variant="plain"
              size="small"
              :slim="true"
              :disabled="item.status.toLowerCase() == 'deleted'"
              @click="setSelectedRow(item)"
            >
              <v-icon icon="mdi-cog" size="20"></v-icon>
              <v-menu :activator="'#api-row-' + item.id">
                <v-list density="compact" class="pa-0">
                  <v-list-item class="pa-0">
                    <v-btn
                      variant="text"
                      class="w-100 justify-start"
                      @click="showRenameDialog = true"
                    >
                      <v-icon icon="mdi-pencil" size="24"></v-icon>
                      <span class="ml-3 text-body-1">Rename</span>
                    </v-btn>
                  </v-list-item>
                  <v-divider></v-divider>
                  <v-list-item
                    v-if="item.status.toLowerCase() === 'active'"
                    class="pa-0"
                  >
                    <v-btn
                      variant="text"
                      class="w-100 justify-start"
                      @click="deactivateConfirmation(item)"
                    >
                      <v-icon icon="mdi-power-standby" size="24"></v-icon>
                      <span class="ml-3 text-body-1">Deactivate</span>
                    </v-btn>
                  </v-list-item>
                  <v-divider></v-divider>
                  <v-list-item
                    v-if="item.status.toLowerCase() === 'deactivated'"
                    class="pa-0"
                  >
                    <v-btn
                      variant="text"
                      class="w-100 justify-start"
                      @click="reactivateConfirmation(item)"
                    >
                      <v-icon icon="mdi-power-standby" size="24"></v-icon>
                      <span class="ml-3 text-body-1">Reactivate</span>
                    </v-btn>
                  </v-list-item>
                  <v-divider></v-divider>
                  <v-list-item class="pa-0">
                    <v-btn
                      variant="text"
                      class="w-100 justify-start text-error"
                      @click="deleteConfirmation()"
                    >
                      <v-icon icon="mdi-delete" size="24"></v-icon>
                      <span class="ml-3 text-body-1">Delete</span>
                    </v-btn>
                  </v-list-item>
                </v-list>
              </v-menu>
            </v-btn>
          </template>
        </v-data-table>
      </v-row>

      <!-- deactivate confirmation dialog-->
      <DynamicDialog
        :show="showDeactivateDialog"
        @dialogOk="deactivateKey(selectedRow)"
        @dialogCancel="showDeactivateDialog = false"
        @update:showDyamicDialog="(state) => (showDeactivateDialog = state)"
        max-width="500"
        okBtnColor="error"
        cancelBtnColor="none"
        cancelBtnVariant="text"
      >
        <template v-slot:title>Deactivate API Key</template>
        <template v-slot:content>
          <p class="pb-4">
            Are you sure you want to deactivate this API key? This will
            temporarily disable access for any applications using this key until
            it is reactivated.
          </p>
        </template>
        <template v-slot:okBtnTitle>DEACTIVATE KEY</template>
      </DynamicDialog>

      <!-- reactivate confirmation dialog-->
      <DynamicDialog
        :show="showReactivateDialog"
        @dialogOk="reactivateKey(selectedRow)"
        @dialogCancel="showReactivateDialog = false"
        @update:showDyamicDialog="(state) => (showReactivateDialog = state)"
        max-width="500"
        cancelBtnColor="none"
        cancelBtnVariant="text"
      >
        <template v-slot:title>Reactivate API Key</template>
        <template v-slot:content>
          <p class="pb-4">
            Are you sure you want to reactivate this API key? This will restore
            access for any applications using this key immediately.
          </p>
        </template>
        <template v-slot:okBtnTitle>REACTIVATE KEY</template>
      </DynamicDialog>

      <!-- delete confirmation dialog-->
      <DynamicDialog
        :show="showDeleteDialog"
        @dialogOk="deleteKey(selectedRow)"
        @dialogCancel="showDeleteDialog = false"
        @update:showDyamicDialog="(state) => (showDeleteDialog = state)"
        max-width="500"
        okBtnColor="error"
        cancelBtnColor="none"
        cancelBtnVariant="text"
      >
        <template v-slot:title>Delete API Key</template>
        <template v-slot:content>
          <p class="pb-4">
            Are you sure you want to delete this API key? This action cannot be
            undone, and any applications using this key will lose access
            immediately.
          </p>
        </template>
        <template v-slot:okBtnTitle>DELETE KEY</template>
      </DynamicDialog>

      <!-- rename confirmation dialog-->
      <DynamicDialog
        :show="showRenameDialog"
        @dialogOk="renameKey(selectedRow)"
        @dialogCancel="showRenameDialog = false"
        @update:showDyamicDialog="(state) => (showRenameDialog = state)"
        :okBtnDisabled="selectedRow?.nickname.length == 0 ?? false"
        max-width="500"
        cancelBtnColor="none"
        cancelBtnVariant="text"
      >
        <template v-slot:title>Rename API Key</template>
        <template v-slot:content>
          <p class="pb-4">
            Enter a new name for this API key. Renaming will not affect the
            key’s functionality or access.
          </p>
          <v-text-field
            variant="outlined"
            label="Nickname"
            v-model="selectedRow.nickname"
            :rules="[validationRules.required]"
            autocomplete="off"
          ></v-text-field>
        </template>
        <template v-slot:okBtnTitle>RENAME KEY</template>
      </DynamicDialog>

      <!-- new key nickname dialog-->
      <DynamicDialog
        :show="showNewKeyNicknameDialog"
        @dialogOk="generateNewKey()"
        @dialogCancel="resetNewKey()"
        @update:showDyamicDialog="(state) => (showNewKeyNicknameDialog = state)"
        :okBtnDisabled="
          newKey.nickname == null || newKey.nickname.length == 0 ? true : false
        "
        max-width="500"
        cancelBtnColor="none"
        cancelBtnVariant="text"
      >
        <template v-slot:title>New API Key</template>
        <template v-slot:content>
          <p class="pb-4">
            Give your new API key a nickname. Once created, you’ll be able to
            view and copy the key as needed.
          </p>
          <v-text-field
            variant="outlined"
            label="Nickname"
            v-model="newKey.nickname"
            :rules="[validationRules.required]"
            autocomplete="off"
          ></v-text-field>
        </template>
        <template v-slot:okBtnTitle>GENERATE KEY</template>
      </DynamicDialog>

      <!-- generated key dialog-->
      <DynamicDialog
        :show="showGeneratedKeyDialog"
        @dialogOk="resetNewKey()"
        @dialogCancel="resetNewKey()"
        @update:showDyamicDialog="(state) => (showGeneratedKeyDialog = state)"
        :cancelBtnHidden="true"
        max-width="500"
        okBtnColor="primary"
      >
        <template v-slot:title>New API Key</template>
        <template v-slot:content>
          <p class="pb-4">
            Your new API key "{{ newKey.nickname }}" has been created. Use this
            key to start making authenticated requests to the datastore API.
          </p>
          <v-text-field
            variant="outlined"
            label="API Key"
            v-model="newKey.key"
            append-inner-icon="mdi-content-copy"
            @click:append-inner="valueToClipboard(newKey.key)"
            readonly
            autocomplete="off"
          >
          </v-text-field>
        </template>
        <template v-slot:okBtnTitle>Close</template>
      </DynamicDialog>
    </v-container>
  </v-theme-provider>
</template>

<script>
import { systemMessages } from "@/mixins/SystemMessages";
import { copyToClipboard } from "@/mixins/CopyToClipboard";
import DynamicDialog from "@/components/DynamicDialog.vue";

export default {
  name: "DatastoreApiKeys",
  components: { DynamicDialog },
  mixins: [systemMessages, copyToClipboard],
  data: () => ({
    kpis: {
      requests: 0,
      errors: 0,
      transferred_mb: 0,
    },
    apiTableHeaders: [],
    tableSort: [{ key: "status", order: "asc" }],
    keysPerPage: 10,
    usageFilterArray: [],
    selectedUsageFilter: null,
    showDeleteDialog: false,
    showDeactivateDialog: false,
    showReactivateDialog: false,
    showRenameDialog: false,
    showNewKeyNicknameDialog: false,
    showGeneratedKeyDialog: false,
    selectedRow: null,
    newKey: { nickname: null, key: null },
    validationRules: {
      required: (value) => !!value || "A value is required",
    },
    snackbarMsg: null,
  }),
  created() {
    this.setUsageFilter();
    this.setApiTableHeaders();
    this.setSnackbarMsg();
    this.fetchApiStats();
  },
  computed: {
    apiKeys: {
      get() {
        return this.$store.getters["datastore/apiKeys"];
      },
      set(val) {
        this.$store.commit("datastore/setApiKeys", val);
      },
    },
    month_transferred_gb: {
      get() {
        return this.round2dp(this.kpis.month_transferred_mb / 1024);
      },
    },
    month_limit_gb: {
      get() {
        return this.round2dp(this.kpis.month_limit_mb / 1024);
      },
    },
    month_usage_percent: {
      get() {
        return this.round2dp(
          (this.kpis.month_transferred_mb / this.kpis.month_limit_mb) * 100,
        );
      },
    },
    usage_bar_colour: {
      get() {
        let colour = "success";

        if (this.month_usage_percent >= 51 && this.month_usage_percent <= 90) {
          colour = "warning";
        }
        if (this.month_usage_percent >= 91 && this.month_usage_percent <= 100) {
          colour = "error";
        }

        return colour;
      },
    },
  },
  methods: {
    /** populate the usage dropdown */
    setUsageFilter(selected = "Last 30 days") {
      this.usageFilterArray = [
        "Last 30 days",
        "This year",
        "All time",
        "Subscription period",
      ];

      this.selectedUsageFilter = selected;
    },

    /** define api keys table headers */
    setApiTableHeaders() {
      this.apiTableHeaders = [
        { title: "Nickname", value: "nickname", sortable: true },
        { title: "Created on", value: "created_at", sortable: true },
        { title: "Last used", value: "last_access", sortable: true },
        { title: "API Key", value: "key", sortable: true },
        { title: "Status", value: "status", sortable: true },
        { value: "action", align: "end" },
      ];
    },

    /** define snackbar messaged based on action being performed */
    setSnackbarMsg() {
      this.snackbarMsg = {
        rename: {
          success: "Api key renamed",
          error: "There was a problem renaming api key",
        },
        reactivate: {
          success: "Api key reactivated",
          error: "There was a problem reactivating api key",
        },
        deactivate: {
          success: "Api key deactivated",
          error: "There was a problem deactivating api key",
        },
        delete: {
          success: "Api key deleted",
          error: "There was a problem deleting api key",
        },
      };
    },

    /** set api keys table status column chip colour */
    getStatusChipColour(status) {
      if (status.toLowerCase() == "active") {
        return "chip-success";
      }
      if (status.toLowerCase() == "deleted") {
        return "chip-error";
      }
      if (status.toLowerCase() == "deactivated") {
        return "chip-default";
      }
    },

    /** obfuscate string in api keys table key column except last 6 digits */
    obfuscateString(value, visibleChars = 6) {
      return value.replace(
        new RegExp(".(?=.{" + visibleChars + "})", "g"),
        "*",
      );
    },

    /** copy supplied value to clipboard canvas */
    async valueToClipboard(value) {
      try {
        //Copy Success
        await copyToClipboard(value);
        this.successPill({
          icon: "mdi-content-copy",
          title: "Copied to clipboard",
        });
      } catch (error) {
        //Copy Error
        this.errorPill({
          icon: "mdi-content-copy",
          title: error.message,
        });
      }
    },

    /** fetch api keys from the backend and update vuex */
    async fetchApiKeys() {
      let filters = JSON.stringify([
        { col: "status", operator: "neq", comparator: "deleted" },
      ]);

      try {
        const response = await this.$axios.get("/datastore/apikeys", {
          params: {
            filters: filters,
          },
        });
        this.apiKeys = response.data;
      } catch (error) {
        this.errorMsg({
          title: "There was an error",
          message: "there was a problem fetching your API keys",
        });
      }
    },

    /** fetch usage stats from backend to populate kpis */
    async fetchApiStats() {
      try {
        const response = await this.$axios.get("/datastore/apistats", {
          params: {
            filter: this.selectedUsageFilter,
          },
        });
        this.kpis = response.data;
      } catch (error) {
        this.errorMsg({
          title: "There was an error",
          message: "there was a problem fetching API usage stats",
        });
      }
    },

    /** update selected api table row */
    setSelectedRow(row) {
      this.selectedRow = { ...row }; //clone row object
    },

    /** save row changes to backend */
    async updateRow(row, action) {
      try {
        await this.$axios.put("/datastore/apikey/" + row.id, row);
        this.showRenameDialog = false;
        this.fetchApiKeys();
        this.successPill({
          title: this.snackbarMsg[action].success,
        });
      } catch (error) {
        this.errorMsg({
          title: this.snackbarMsg[action].error,
        });

        throw error; //rethrow for catching downstream
      }
    },

    /** rename an api key */
    async renameKey(row) {
      row.nickname = row.nickname.trim();

      try {
        await this.updateRow(row, "rename");
        this.showRenameDialog = false;
        this.fetchApiKeys();
      } catch {
        //handled by parent
      }
    },

    /** reactivate an api key */
    reactivateConfirmation() {
      this.showReactivateDialog = true;
    },

    /** reactivate an api key */
    async reactivateKey(row) {
      row.status = "active";

      try {
        await this.updateRow(row, "reactivate");
        this.showReactivateDialog = false;
        this.fetchApiKeys();
      } catch {
        //handled by parent
      }
    },

    /** show deactivate confirmation dialog */
    deactivateConfirmation() {
      this.showDeactivateDialog = true;
    },
    /** deactivate an api key */
    async deactivateKey(row) {
      row.status = "deactivated";

      try {
        await this.updateRow(row, "deactivate");
        this.showDeactivateDialog = false;
        this.fetchApiKeys();
      } catch {
        //handled by parent
      }
    },

    /** show delete confirmation dialog */
    deleteConfirmation() {
      this.showDeleteDialog = true;
    },

    /** delete an api key */
    async deleteKey(row) {
      row.status = "deleted";

      try {
        await this.updateRow(row, "delete");
        this.showDeleteDialog = false;
        this.fetchApiKeys();
      } catch {
        //handled by parent
      }
    },

    /** show new api key nickname dialog */
    newKeyDialog() {
      this.showNewKeyNicknameDialog = true;
    },

    /** create a new key record on the backend and display to user*/
    async generateNewKey() {
      try {
        const response = await this.$axios.post(
          "/datastore/apikey",
          this.newKey,
        );
        this.showNewKeyNicknameDialog = false;
        this.newKey = response.data;
        this.showGeneratedKeyDialog = true;
        this.fetchApiKeys();
      } catch (error) {
        this.errorMsg({
          title: "There was an error",
          message: "unable to generate new api key",
        });
      }
    },

    /** clear new key details once dialog is closed - ready for next one */
    resetNewKey() {
      this.showNewKeyNicknameDialog = false;
      this.showGeneratedKeyDialog = false;
      this.newKey = { nickname: null, key: null };
    },

    /** helper function to round any number to 2dp e.g 3.44666666 to 3.45*/
    round2dp(num) {
      return Math.round(num * 100) / 100;
    },
  },
};
</script>

<style scoped>
.kpi-card,
.usage-card {
  text-align: left;
  padding-top: 12px;
  padding-bottom: 12px;
  color: #4d4d4d;
}

.kpi-card .title {
  font-size: 32px;
  font-weight: 600;
  line-height: 1em;
}

.usage-card .title {
  font-size: 22px;
}

.kpi-card .subtitle {
  font-size: 14px;
  font-weight: 500;
}

.kpi-card .subtitle i {
  width: 20px;
  height: 20px;
  margin-right: 8px;
}

:deep(.v-data-table__td .v-btn) {
  min-width: 20px !important;
  width: 20px !important;
  padding: 4px !important;
}

.chip-success {
  color: rgb(var(--v-on-inverted-success));
  background-color: rgb(var(--v-inverted-success));
}

.chip-default {
  color: rgb(var(--v-on-inverted-default));
  background-color: rgb(var(--v-inverted-default));
}

.chip-error {
  color: rgb(var(--v-on-inverted-error));
  background-color: rgb(var(--v-inverted-error));
}
</style>
